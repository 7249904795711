<app-loading *ngIf="isLoading"></app-loading>
<app-suggested-risks-list
  *ngIf="!isLoading && this.selectedRiskType === riskTypes.Risk && currentStep === 1 && useRiskSearch"
  [isWithinAddRiskModal]="true"
  (prefillFormEvent)="handlePrefillForm($event)">
</app-suggested-risks-list>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center border-0">
    <span class="modal-title" [ngClass]="isMobile ? 'pt-3' : ''">{{ T.common.create_item | translate: { item: getSelectedRiskName } }}</span>
    <span class="material-symbols-outlined close-icon" (click)="confirmClose()">close</span>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-symbols-outlined">error_outline</i>
      {{ error }}
    </div>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters pt-1" [formGroup]="form">
    <div *ngIf="currentStep === 1" class="col-12 d-flex radio-buttons mt-3">
      <app-radio-button
        [useBiggerCheckmark]="true"
        [checked]="this.selectedRiskType === riskTypes.Risk"
        [text]="localiseRiskType(riskTypes.Risk)"
        (checkedChanged)="selectType(riskTypes.Risk)">
      </app-radio-button>
      <app-radio-button
        [useBiggerCheckmark]="true"
        [checked]="this.selectedRiskType === riskTypes.Issue"
        [text]="localiseRiskType(riskTypes.Issue)"
        (checkedChanged)="selectType(riskTypes.Issue)">
      </app-radio-button>
      <app-radio-button
        [useBiggerCheckmark]="true"
        [checked]="this.selectedRiskType === riskTypes.Opportunity"
        [text]="localiseRiskType(riskTypes.Opportunity)"
        (checkedChanged)="selectType(riskTypes.Opportunity)">
      </app-radio-button>
    </div>

    <div *ngIf="currentStep === 1" class="col-12">
      <app-hr></app-hr>
    </div>

    <app-threaded-beads [beads]="beads" [useSmallBeads]="true" ></app-threaded-beads>

    <ng-container *ngIf="currentStep === 1 && !isLoading">
      <div class="selection col-12 mb-2">
        <app-text-field
          [ngClass]="{'not-editable-title': usedPreDefinedRisk}"
          [showOutlined]="true"
          [removeBorders]="true"
          [displayError]="triggerErrors"
          [formGroup]="form"
          formControlName="riskTitle"
          [useReactiveMessages]="true"
          [maxLength]="250"
          [disableMargin]="true"
          [required]="true"
          [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'">
        </app-text-field>
      </div>
      <div class="col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [filterByModuleObjectType]="false"
          [displayError]="triggerErrors"
          [required]="true"
          contentEditable="false"
          [currentlySelectedSettingValue]="statusValue.toString()"
          [filterType]="filterTypes.Risk_Status"
          [excludeFilterValues]="exludeRiskStatuses"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="(T.common.status.one | translate) + '*'"
          [disableMargin]="true"
          (onFilterUpdate)="handleFilterChange($event)"
          (deselectionChange)="onObjectDeselected($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="col-6 pe-2 mb-2">
        <div class="selection">
          <app-filter-dropdown-new
            [useSearch]="false"
            [showOutlined]="true"
            [removeBorders]="true"
            [label]="'Probability*'"
            [disabled]="selectedRiskType === riskTypes.Issue"
            [displayError]="triggerErrors"
            [disableRequiredText]="true"
            [excludeFilterValues]="excludeDropdownValuesArr"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Risk_Likelihood"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [disableMargin]="true"
            [required]="true"
            (onFilterUpdate)="handleFilterChange($event)"
            [currentlySelectedSettingValue]="likelihoodValue">
          </app-filter-dropdown-new>
        </div>
      </div>
      <div class="col-6 ps-2 mb-2">
        <div class="selection">
          <app-filter-dropdown-new
            [useSearch]="false"
            [showOutlined]="true"
            [removeBorders]="true"
            [label]="'Impact*'"
            [displayError]="triggerErrors"
            [disableRequiredText]="true"
            [required]="true"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Risk_Impact"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [disableMargin]="true"
            [currentlySelectedSettingValue]="impactValue.toString()"
            (onFilterUpdate)="handleFilterChange($event)">
          </app-filter-dropdown-new>
        </div>
      </div>
      <div class="col-12 mb-2">
        <div [ngTemplateOutlet]="riskRAGTemplate"></div>
      </div>
      <div class="col-12">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [useRadioButtons]="true"
          [displayError]="triggerErrors"
          [required]="true"
          [currentlySelectedSettingValue]="0"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Privacy_Status"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="(T.common.privacy.one | translate) + '*'"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="description col-12 mb-2">
        <app-details-editable-field
          [useOutlinedInput]="true"
          [input]="form.controls.riskDescription.value ? form.controls.riskDescription.value : ''"
          [type]="editableFieldTypes.RichText"
          [canEdit]="true"
          (onUpdate)="updateRiskDescription($event)"
          (onInputChange)="updateRiskDescription($event)"
          [placeholder]="T.common.add_description | translate">
        </app-details-editable-field>
      </div>
      <div class="container-with-bottom-element d-flex flex-column col-12 mb-2">
        <div class="selection">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [displayError]="triggerErrors"
            [filterType]="filterTypes.Owner"
            [currentlySelectedSettingValue]="ownerValue"
            [required]="true"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [disableMargin]="false"
            [typeahead]="true"
            [removeIcon]="true"
            [removeBorders]="true"
            [showOutlined]="true"
            [useSearch]="false"
            [label]="(T.defaultLocalizations.owner.one | translate) + '*'"
            (onFilterUpdate)="handleFilterChange($event)"
            (deselectionChange)="onObjectDeselected($event)">
          </app-filter-dropdown-new>
        </div>
        <div class="d-flex">
          <app-button-transparent
            size="small"
            icon="add"
            [text]="T.common.assign_me | translate"
            (clicked)="setAccountForCurrentOwner()">
          </app-button-transparent>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 2 && !isLoading">
      <div class="selection col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Impact_Type"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.impact_type.many | translate: {impact: localisedImpact}"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="selection col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [disableMargin]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Event_Type"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]=""
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="selection col-12 mb-2">
        <app-filter-dropdown-new
          [showOutlined]="true"
          [removeBorders]="true"
          [useSearch]="false"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Strategy"
          [currentlySelectedSettingValue]="riskStrategyValue.toString()"
          [singleSelect]="true"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.common.strategy.one | translate"
          (onFilterUpdate)="handleFilterChange($event)"
          (deselectionChange)="onObjectDeselected($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="selection col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Department"
          [disableMargin]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [removeIcon]="true"
          [label]="localisedDepartment"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="bottom-element d-flex ms-3" *ngIf="employee?.departmentId > 0">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="setMyDepartment()"
        ></app-button-transparent>
      </div>
      <div class="col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Project"
          [disableMargin]="true"
          [removeIcon]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="col-12 d-flex mb-2" *ngIf="!dateReseter">
        <app-datepicker
          [date]="getDueDate"
          [minDate]="today"
          (dateChanged)="setDueDate($event)"
          [placeholder]="(T.defaultLocalizations.due_date.one | translate) + '*'"
          [showPlaceholderFirst]="true"
          [disableMargin]="true"
          [showOutlined]="true">
        </app-datepicker>
      </div>
      <div class="col-12 mb-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [showOutlined]="true"
          [removeBorders]="true"
          [disableMargin]="true"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Identified_By"
          [currentlySelectedSettingValue]="riskIdentifiedByValue.toString()"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.defaultLocalizations.identified_by.one | translate"
          (onFilterUpdate)="handleFilterChange($event)"
          (deselectionChange)="onObjectDeselected($event)">
        </app-filter-dropdown-new>
      </div>
      <div class="d-flex align-items-center col-12 mt-3 mb-0">
        <span class="risk-index me-3">
          {{ T.defaultLocalizations.tag.many | translate }}
        </span>
        <div class="flex-grow-1">
          <app-hr></app-hr>
        </div>
      </div>
      <app-add-modal-tag-groups
        class="col-12 mt-0"
        [showTitle]="false"
        [showOutlined]="true"
        [removeBorders]="true"
        [useSearch]="false"
        [removeIcon]="true"
        [disableMargin]="true"
        [layoutClasses]="'col-12 mb-2'"
        [filters]="filters"
        [tagGroupObjectType]="objectTypes.Risk"
        (onFiltersUpdated)="handleFilterChange($event)">
      </app-add-modal-tag-groups>
    </ng-container>

    <ng-container *ngIf="currentStep === 3 && !isLoading">
      <div class="d-flex align-items-center col-12 mt-1 mb-2" *ngIf="actionGroups.controls.length > 1">
        <span class="risk-index me-3">
          Risk action 1
        </span>
        <div class="flex-grow-1">
          <app-hr></app-hr>
        </div>
      </div>
      <div formArrayName="actionGroups" class="col-12">
        <div *ngFor="let group of actionGroups.controls; let i = index" [formGroupName]="i">
          <div class="d-flex align-items-center col-12 mt-4 mb-3" *ngIf="i > 0"> <!-- Show for additional actions -->
            <span class="risk-index me-3">
              Risk action {{ i + 1 }}
            </span>
            <div class="flex-grow-1">
              <app-hr></app-hr>
            </div>
          </div>
          <div class="col-12 mb-3">
            <app-text-field
              [formGroup]="group"
              formControlName="title"
              [showOutlined]="true"
              [removeBorders]="true"
              [displayError]="triggerErrors"
              [useReactiveMessages]="true"
              [maxLength]="250"
              [disableMargin]="true"
              [placeholder]="(T.defaultLocalizations.action_item.one | translate) + ' ' +
                (T.defaultLocalizations.title.one | translate).toLowerCase() + '*'">
            </app-text-field>
          </div>
          <div class="col-12 mb-3">
            <app-filter-dropdown-new
              [useSearch]="false"
              [showOutlined]="true"
              [removeBorders]="true"
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Risk_Action_Type"
              [currentlySelectedSettingValue]="group.value.type"
              [disableMargin]="true"
              [removeIcon]="true"
              [typeahead]="true"
              [singleSelect]="true"
              [displayError]="triggerErrors"
              [label]="(T.defaultLocalizations.action_type.one | translate) + '*'"
              (onFilterUpdate)="handleActionFilterChange($event, i, 'type')"
              (deselectionChange)="onObjectDeselected($event, i)">
            </app-filter-dropdown-new>
          </div>
          <div class="col-12 mb-3">
            <app-filter-dropdown-new
              [useSearch]="false"
              [showOutlined]="true"
              [removeBorders]="true"
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Risk_Action_Status"
              [currentlySelectedSettingValue]="group.value.status"
              [disableMargin]="true"
              [removeIcon]="true"
              [typeahead]="true"
              [singleSelect]="true"
              [displayError]="triggerErrors"
              [label]="(T.common.status.one | translate) + ' *'"
              (onFilterUpdate)="handleActionFilterChange($event, i, 'status')"
              (deselectionChange)="onObjectDeselected($event, i)">
            </app-filter-dropdown-new>
          </div>
          <div class="description col-12 mb-3">
            <app-details-editable-field
              [useOutlinedInput]="true"
              [input]="group.get('description').value || ''"
              [type]="editableFieldTypes.RichText"
              [canEdit]="true"
              (onUpdate)="updateActionDescription($event, i)"
              (onInputChange)="updateActionDescription($event,i )"
              [placeholder]="T.common.add_description | translate">
            </app-details-editable-field>
          </div>
          <div class="col-12 d-flex mb-3" *ngIf="!dateReseter">
            <app-datepicker
              [minDate]="today"
              [date]="group.get('dueDate').value || ''"
              (dateChanged)="setActionDueDate($event, i)"
              [placeholder]="T.defaultLocalizations.due_date.one | translate"
              [showPlaceholderFirst]="true"
              [disableMargin]="true"
              [showOutlined]="true">
            </app-datepicker>
          </div>
          <div class="container-with-bottom-element d-flex flex-column col-12 mb-2">
            <div class="selection">
              <app-filter-dropdown-new
                [showOutlined]="true"
                [removeBorders]="true"
                [removeIcon]="true"
                [useSearch]="false"
                [filterByModuleObjectType]="false"
                [displayError]="triggerErrors"
                [filterType]="filterTypes.Owner"
                [disableMargin]="true"
                [typeahead]="true"
                [label]="(T.defaultLocalizations.owner.many | translate)"
                [appliedFilters]="actionGroupFilters[i]"
                [isActionsGroup]="true"
                (onFilterUpdate)="handleActionFilterChange($event, i, 'filters')"
                (deselectionChange)="onObjectDeselected($event, i)">
              </app-filter-dropdown-new>
            </div>
            <div class="d-flex mt-1">
              <app-button-transparent
                size="small"
                icon="add"
                [text]="T.common.assign_me | translate"
                (clicked)="setAccountForCurrentOwner(isAction, i)">
              </app-button-transparent>
            </div>
          </div>
          <div class="col-12 mb-3">
            <app-filter-dropdown-new
              [showOutlined]="true"
              [removeBorders]="true"
              [removeIcon]="true"
              [useSearch]="false"
              [filterByModuleObjectType]="false"
              [displayError]="triggerErrors"
              [filterType]="filterTypes.User_Group"
              [disableMargin]="true"
              [typeahead]="true"
              [label]="(T.common.group.many | translate)"
              (onFilterUpdate)="handleActionFilterChange($event, i, 'userGroup')"
              [appliedFilters]="userGroupsFilters[i]"
              [isActionsGroup]="true"
              (deselectionChange)="onObjectDeselected($event, i)">
            </app-filter-dropdown-new>
          </div>
        </div>
      </div>
      <div class="col-12 mt-0 mb-1">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="'Add more actions'"
          (clicked)="addMoreActions()">
        </app-button-transparent>
      </div>
    </ng-container>

    <div class="buttons-row col-12 d-flex align-items-center"
         [ngStyle]="{ justifyContent: currentStep === 1 ? 'flex-end' : 'space-between' }">
      <div *ngIf="currentStep !== 1" class="back-button d-flex" tabindex="0" (click)="goToPreviousStep()">
        <span class="material-symbols-outlined fs-5 me-2">keyboard_backspace</span>
        <span class="back-button-text">{{ (T.common.back | translate).toUpperCase() }}</span>
      </div>
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          [size]="ButtonSizes.Large"
          [text]="T.common.cancel | translate"
          buttonTheme="white"
          (clicked)="confirmClose()">
        </app-button-rectangle>
        <app-button-rectangle
          [size]="ButtonSizes.Large"
          class="modal-confirm"
          [buttonTheme]="ButtonThemes.Primary"
          [text]="currentStepButton"
          [disabled]="!currentStepIsValid"
          [loading]="isLoading"
          (clicked)="onConfirm($event)">
        </app-button-rectangle>
      </div>
    </div>
  </div>

  <ng-template #riskRAGTemplate>
    <div [ngClass]="{
      low: ragColour === '#329F5B',
      moderate: ragColour === '#FDD835',
      medium: ragColour === '#FD9C38',
      high: ragColour === '#FF6D3F',
      significant: ragColour === '#F41B1B'
    }"
    class="risk-rag d-flex">
      <div class="details-icon">
        <app-details-icon [size]="24" [type]="riskTypes[selectedRiskType]" [backgroundFill]="ragColour"></app-details-icon>
      </div>
      <div>
        <span class="ms-2">{{ ragDescription }}</span>
      </div>
    </div>
  </ng-template>
</div>

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TextFieldComponent } from '../text-field/text-field.component';

@Component({
  selector: 'app-simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleDropdownComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChild('simpleDropdownContent', { static: false }) simpleDropdownContent: ElementRef<HTMLDivElement>;
  @ViewChild('mobileInput', { static: false }) mobileInput: ElementRef<HTMLInputElement>;
  @ViewChildren(TextFieldComponent) textFieldComponent: QueryList<TextFieldComponent>;

  @Input() icon: string;
  @Input() svg: string;
  @Input() objects: object[];
  @Input() bindProperty: string;
  @Input() label: string;
  @Input() multiSelect: boolean;
  @Input() singleSelect: boolean = false;
  @Input() isActionsGroup: boolean = false;

  @Input() currentlySelectedObject: object;
  @Input() currentlySelectedObjects: object[] = [];
  @Input() compareBy: string;
  @Input() typeahead: Subject<string>;
  @Input() disableMargin: boolean = false;
  @Input() disabled: boolean = false;
  @Input() areAllSelected: boolean = false;
  @Input() showOutlined: boolean = false;
  @Input() removeBorders: boolean = false;
  @Input() filterType: FilterTypes;

  @Output() objectSelected = new EventEmitter<object>();
  @Output() objectCleared = new EventEmitter<void>();
  @Output() applyChanges = new EventEmitter<object[]>();
  @Output() cancelChanges = new EventEmitter<void>();
  @Output() deselectionChange = new EventEmitter<any>();

  private readonly _debounceTime: number = 420;
  private readonly _mobileWidth: number = Constants.xs;
  private readonly _objectSelectedProperty: string = 'selected';
  private readonly _ngDropdownVisibleClass: string = 'ng-dropdown-visible';

  get dropdownItemHeight(): number {
    return this.mobile ? 44 : 32;
  }

  expanded: boolean;
  filteredObjects: object[];
  searchValue: string = null;
  searchValueSubject: Subject<string> = new Subject();
  simpleDropdownMobileVisible: boolean;
  cdkVirtualForViewChangeSubscription: Subscription;
  subscriptions: Subscription[] = [];
  contentHeight = '200px';
  appliedFilters = [];
  newlySelected = [];
  public readonly filterTypes = FilterTypes;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.push(
      this.searchValueSubject.pipe(debounceTime(this._debounceTime)).subscribe((value) => {
        if (this.typeahead) {
          this.typeahead.next(value);
        } else {
          this.initFilteredObjects(this.searchValue);
        }
      })
    );

    this.checkIfAllAreSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.objects) {
      if (this.mobile) {
        this.contentHeight = '100%';
      } else {
        if (this.objects && this.objects.length < 4) {
          const height = 42 + this.objects.length * 32;
          this.contentHeight = `${height}px`;
        } else {
          this.contentHeight = '200px';
        }
      }
    }

    if (this.multiSelect) {
      this.initFilteredObjects(this.searchValue);
    } else if (changes.objects || changes.currentlySelectedObject) {
      if (changes.currentlySelectedObject && changes.currentlySelectedObject.currentValue) {
        this.searchValue = changes.currentlySelectedObject.currentValue[this.bindProperty].toString();
        this.initFilteredObjects(this.searchValue);
      } else {
        this.initFilteredObjects();
      }
    }
  }

  ngAfterViewInit() {
    if (!this.mobile) {
      this.subscriptions.push(
        this.ngDropdownDirective.dropdownToggled.subscribe((toggled) => {
          this.expanded = toggled;
          this.changeDetectorRef.detectChanges();

          if (!toggled) {
            this.input.blur();
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onBlur() {
    this.expanded = false;
    this.changeDetectorRef.markForCheck();
  }

  onClick() {
    if (this.mobile) {
      if (this.simpleDropdownMobileVisible) {
        this.expanded = true;
      } else {
        this.simpleDropdownMobileVisible = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.multiSelect) {
      return;
    }

    if (
      this.currentlySelectedObject &&
      this.removeHTMLTags(this.currentlySelectedObject[this.bindProperty]) === this.searchValue
    ) {
      this.filteredObjects = this.objects.map((o) => {
        o[this._objectSelectedProperty] = undefined;

        return o;
      });
      this.changeDetectorRef.detectChanges();
    }

    if (this.cdkVirtualForViewChangeSubscription) {
      this.cdkVirtualForViewChangeSubscription.unsubscribe();
    }

    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualForViewChangeSubscription = this.cdkVirtualScrollViewport.scrolledIndexChange.subscribe(() => {
        this.reflectPositionDependingOnValue(
          this.currentlySelectedObject &&
            this.removeHTMLTags(this.currentlySelectedObject[this.bindProperty]) === this.searchValue
            ? undefined
            : this.searchValue
        );
        if (this.cdkVirtualForViewChangeSubscription) {
          this.cdkVirtualForViewChangeSubscription.unsubscribe();
        }
      });

      this.cdkVirtualScrollViewport.getElementRef().nativeElement.dispatchEvent(new Event('scroll'));
    }
  }

  onInput() {
    this.searchValueSubject.next(this.searchValue);
    this.checkIfAllAreSelected();
  }

  onClear(e: Event) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    const value = this.searchValue;

    this.searchValue = null;
    this.filteredObjects = this.objects.map((o) => {
      o[this._objectSelectedProperty] = undefined;

      return Object.assign({}, o);
    });
    this.changeDetectorRef.detectChanges();
    this.currentlySelectedObject = undefined;
    this.reflectPositionDependingOnValue(value);

    this.objectCleared.emit();

    if (
      this.simpleDropdownContent &&
      !this.simpleDropdownContent.nativeElement?.parentElement?.classList.contains(this._ngDropdownVisibleClass)
    ) {
      this.input.parentElement.parentElement.dispatchEvent(new Event('click'));
    }

    if (!this.mobile) {
      this.ngDropdownDirective.updatePosition();
    }

    this.input.focus();

    this.checkIfAllAreSelected();
  }

  onIconsClick(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.input.parentElement.parentElement.dispatchEvent(new Event('click'));

    if (this.expanded) {
      this.input.focus();
    } else {
      this.input.blur();
    }
  }

  objectIsSelected(object: object): boolean {
    if (this.multiSelect) {
      return (
        object[this._objectSelectedProperty] || !!this.currentlySelectedObjects.find((o) => this.equalityPredicate(o, object))
      );
    } else {
      return object[this._objectSelectedProperty] || this.equalityPredicate(object, this.currentlySelectedObject);
    }
  }

  onObjectSelected(object: object) {
    if (this.isActionsGroup) {
      if (this.objectIsSelected(object)) {
        return;
      }

      this.currentlySelectedObjects.push(object);
      this.objectSelected.emit(object);

      if (this.multiSelect) {
        this.input.focus();
        this.expanded = true;
      } else {
        this.searchValue = this.removeHTMLTags(object[this.bindProperty]);
        this.currentlySelectedObject = object;
      }

      if (!this.mobile) {
        if (this.multiSelect) {
          this.ngDropdownDirective.updatePosition();
        } else {
          this.ngDropdownDirective.onEscape();
        }
      } else if (!this.multiSelect) {
        this.simpleDropdownMobileVisible = false;
        this.ngDropdownDirective.onEscape();
      }

      this.checkIfAllAreSelected();
      return;
    }

    if (this.objectIsSelected(object)) {
      this.currentlySelectedObjects = this.currentlySelectedObjects.filter(selectedObject => selectedObject !== object);
    }
    else {
      this.currentlySelectedObjects.push(object);
    }

    this.objectSelected.emit(object);

    if (this.multiSelect) {
      this.input.focus();
      this.expanded = true;
    }
    else {
      this.searchValue = this.removeHTMLTags(object[this.bindProperty]);
      this.currentlySelectedObject = object;
    }

    if (!this.mobile) {
      if(this.multiSelect) {
        this.ngDropdownDirective.updatePosition();
      } else {
        this.ngDropdownDirective.onEscape();
      }
    } else if (!this.multiSelect) {
      this.simpleDropdownMobileVisible = false;
      this.ngDropdownDirective.onEscape();
    }

    this.checkIfAllAreSelected();
  }

  handleDeselectionChange(object: any) {
    this.deselectionChange.emit(object);
    this.searchValue = '';
    this.filteredObjects = [...this.objects];
    this.ngDropdownDirective.onEscape();
  }

  onSelectAll(): void {
    if (this.multiSelect) {
      this.currentlySelectedObjects = this.objects.slice();
      this.checkIfAllAreSelected();

      this.changeDetectorRef.markForCheck();
    }
  }

  onClearAll(): void {
    this.currentlySelectedObjects = [];
    this.checkIfAllAreSelected();

    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    this.simpleDropdownMobileVisible = false;

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.cancelChanges.next();
    this.changeDetectorRef.markForCheck();
  }

  get input(): HTMLInputElement {
    if (!this.textFieldComponent) {
      return null;
    }

    return this.textFieldComponent.last.input.nativeElement;
  }

  get mobile(): boolean {
    return window.innerWidth <= this._mobileWidth;
  }

  private equalityPredicate(a: object, b: object): boolean {
    if (!a || !b) {
      return false;
    }

    if (this.compareBy) {
      return a[this.compareBy] === b[this.compareBy];
    } else {
      return a === b || this.removeHTMLTags(a[this.bindProperty]) === this.removeHTMLTags(b[this.bindProperty]);
    }
  }

  private removeHTMLTags(text: string): string {
    if (!text || !text.length) {
      text = '';
    }

    const highlighted: RegExp = /<span class="highlighted">(.+?)<\/span>/g;
    const regexMatch: RegExpMatchArray = highlighted.exec(text);

    let replacement = '';

    if (regexMatch) {
      const [, group1] = Array.from(regexMatch);

      replacement = group1;
    }

    return text.replace(highlighted, replacement);
  }

  private getFilteredObjectsBySearchValue(value: string): object[] {
    value = value.toLowerCase();

    return this.objects
      .filter((object) => {
        const stripped = this.removeHTMLTags(object[this.bindProperty]).toLowerCase();

        return stripped.indexOf(value) !== -1;
      })
      .map((object) => {
        const stripped = this.removeHTMLTags(object[this.bindProperty]).toLowerCase();

        const startIndex = stripped.indexOf(value);
        const endIndex = startIndex + value.length;

        if (startIndex >= 0 && endIndex <= stripped.length) {
          let replacement = '<span class="highlighted">' + stripped.substring(startIndex, endIndex) + '</span>';

          if (startIndex > 0) {
            replacement = stripped.substring(0, startIndex) + replacement;
          }

          if (endIndex < stripped.length) {
            replacement = replacement + stripped.substring(endIndex);
          }

          const assignee = {};

          assignee[this.bindProperty] = object[this.bindProperty].replace(stripped, replacement);

          object[this._objectSelectedProperty] = false;
          object[this._objectSelectedProperty] = this.objectIsSelected(object);

          object = Object.assign({}, object, assignee);
        }

        return object;
      });
  }

  private reflectPosition() {
    if (this.multiSelect) {
      return;
    }

    if (!this.currentlySelectedObject) {
      return;
    }

    const index: number = this.objects.findIndex((o) => this.equalityPredicate(o, this.currentlySelectedObject));

    this.cdkVirtualScrollViewport.scrollToIndex(index);
  }

  private reflectPositionDependingOnValue(value: string = undefined) {
    if (!this.cdkVirtualScrollViewport) {
      return;
    }

    if (value) {
      this.cdkVirtualScrollViewport.scrollToIndex(+false);
    } else {
      this.reflectPosition();
    }
  }

  private initFilteredObjects(value: string = undefined) {
    if (value) {
      this.filteredObjects = this.getFilteredObjectsBySearchValue(value);
      this.changeDetectorRef.detectChanges();
    } else {
      this.filteredObjects = this.objects;
    }

    this.reflectPositionDependingOnValue(value);
  }

  onApply() {
    this.applyChanges.next(this.currentlySelectedObjects);

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.simpleDropdownMobileVisible = false;
  }

  onDropdownToggled(state: boolean) {
    if (this.multiSelect) {
      this.applyChanges.next(this.currentlySelectedObjects);
    }
  }

  get clearButtonDisabled(): boolean {
    let res = false;

    if (this.multiSelect) {
      res = !this.currentlySelectedObjects || !this.currentlySelectedObjects.length;
    } else {
      res = !this.searchValue;
    }

    return res;
  }

  private checkIfAllAreSelected(): void {
    if (!this.objects.length || this.searchValue) {
      this.areAllSelected = true;
    } else {
      this.areAllSelected =
        !this.currentlySelectedObjects || !this.objects || this.currentlySelectedObjects.length === this.objects.length;
    }
  }
}

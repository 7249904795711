import { Injectable } from '@angular/core';
import { timer, Observable, Subscription } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { GlobalObjectItemCount } from '../models/globalObjectItemCount';
import { CachingService } from './caching.service';
import { IncidentsService } from './incidents.service';
import { Employee } from '../models/employee';
import { ObjectTypes } from '../enums/objectTypes';
import { Account } from '../models/account';
import { Router } from '@angular/router';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { IncidentListItemViewModel } from '../../incidents/viewModels/incidentListItemViewModel';
import { HeaderFiltersService } from './header-filters.service';

@Injectable()
export class ContinousService {
  private globalObjectItemCount: GlobalObjectItemCount[] = [];
  public account: Account;
  public employee: Employee;
  subscriptions: Subscription[] = [];

  constructor(
    private cachingService: CachingService,
    private incidentService: IncidentsService,
    private router: Router,
    private readonly headerFiltersService: HeaderFiltersService
  ) {
    this.account = this.cachingService.GetAccountInfo();
    if (this.account) this.employee = this.cachingService.GetEmployee(this.account.id);
  }

  public initContinousSubscriptions() {
    if (this.account && this.account.useIMS && this.employee) {
      this.subscriptions.push(
        this.getIncidentItemsContinuous(this.headerFiltersService.currentFilters).subscribe((items) => {
          if (items && items.length) {
            if (!this.globalObjectItemCount.length) {
              const itemCountModel = new GlobalObjectItemCount(ObjectTypes.IncidentItem, items.length);
              this.globalObjectItemCount.push(itemCountModel);
            } else {
              const incidentCountModel = this.globalObjectItemCount.find((i) => i.objectType == ObjectTypes.IncidentItem);

              if (incidentCountModel && items.length > incidentCountModel.itemCount) {
                const incidentsAdded = items.length - incidentCountModel.itemCount;
                this.globalObjectItemCount.find((i) => i.objectType == ObjectTypes.IncidentItem).itemCount = items.length;
              }
            }
          }
        })
      );
    }
  }

  public getIncidentItemsContinuous(filters: FilterViewModel[]) {
    return timer(0, 20000).pipe(
      filter(() => this.isIncidentsRoute),
      switchMap(() => this.getIncidentItems(filters))
    );
  }

  public getIncidentItems(filters: FilterViewModel[]): Observable<IncidentListItemViewModel[]> {
    return this.incidentService.list(filters);
  }

  get isIncidentsRoute(): boolean {
    return this.router.url.startsWith(`/${this.account.accountUID}/control`);
  }

  get isRunsheetsRoute(): boolean {
    return this.router.url.startsWith(`/${this.account.accountUID}/runsheets`);
  }
}

import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { EmployeeSettingsService } from "./employee-settings.service";
import { EmployeeSettingTypes } from "../../settings/enums/employeeSettingTypes";
import { ObjectTypes } from "../enums/objectTypes";
import { IncidentItemTypes } from "../../incidents/enums/incidentItemTypes";
import { EmployeeSettingViewModel } from "../viewModels/employeeSettingViewModel";
import { AlertService } from "./alert.service";
import { TranslateService } from "@ngx-translate/core";
import { T } from 'src/assets/i18n/translation-keys';

export type ShowLozengeSetting = {
  ObjectType: ObjectTypes,
  ObjectSubType: IncidentItemTypes | 0,
  ShowLozenges: boolean
}

@Injectable({
  providedIn: 'root',
})
export class ShowLozengesService implements OnDestroy {
  private readonly T = T;

  public showLozengeSettings = new BehaviorSubject<ShowLozengeSetting[]>([])
  showLozengeSettings$ = this.showLozengeSettings.asObservable();

  private employeeSetting: EmployeeSettingViewModel;

  protected readonly subscriptions = new Subscription();

  constructor(
    protected readonly employeeSettingsService: EmployeeSettingsService,
    protected readonly alertService: AlertService,
    protected readonly translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initShowLozengeEmployeeSetting(): void {
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Show_Lozenges)
        .subscribe(res => {
          this.employeeSetting = res;
          this.showLozengeSettings.next(JSON.parse(res.value) as ShowLozengeSetting[]);
        }
      )
    )
  }

  getShowLozengeSettingForAnObjType(settings: ShowLozengeSetting[], objectType: ObjectTypes, objectSubType?: IncidentItemTypes): boolean {
    if (!objectSubType) {
      return settings.find(setting => setting.ObjectType === objectType)?.ShowLozenges;
    }

    return settings.find(setting => setting.ObjectType === objectType && setting.ObjectSubType === objectSubType)?.ShowLozenges;
  }

  updateShowLozengeSetting(newValue: boolean, objectType: ObjectTypes, objectSubType?: IncidentItemTypes) {
    const newShowLozengeSetting: ShowLozengeSetting = {
      ObjectType: objectType,
      ObjectSubType: objectSubType ? objectSubType : 0,
      ShowLozenges: newValue
    }

    const showLozengesSettingsArr = [...JSON.parse(this.employeeSetting.value)];

    let filteredShowLozengesSettingsArr: ShowLozengeSetting[];

    if (!objectSubType) filteredShowLozengesSettingsArr = showLozengesSettingsArr.filter(s => s.ObjectType !== objectType);
    if (objectType === ObjectTypes.IncidentItem) filteredShowLozengesSettingsArr = showLozengesSettingsArr.filter(s => s.ObjectSubType !== objectSubType);

    filteredShowLozengesSettingsArr.push(newShowLozengeSetting);

    this.employeeSetting.value = JSON.stringify(filteredShowLozengesSettingsArr);

    this.subscriptions.add(
      this.employeeSettingsService.updateEmployeeSetting(EmployeeSettingTypes.Show_Lozenges, this.employeeSetting)
        .subscribe(() => {
          this.showLozengeSettings.next(filteredShowLozengesSettingsArr);
          void this.alertService.success(this.translateService.instant(T.common.lozenges_saved_successfully));
        }
      )
    )
  }
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
  HostListener,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TextFieldComponent } from '../../common/text-field/text-field.component';

@Component({
  selector: 'app-search-dropdown-r',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDropdownComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChild('searchDropdownContent', { static: false }) searchDropdownContent: ElementRef<HTMLDivElement>;
  @ViewChild('mobileInput', { static: false }) mobileInput: ElementRef<HTMLInputElement>;
  @ViewChild(TextFieldComponent) textFieldComponent: TextFieldComponent;
  @ViewChild(TextFieldComponent, { read: ElementRef }) textFieldComponentElementRef: ElementRef<HTMLElement>;

  @Input() icon: string;
  @Input() svg: string;
  @Input() objects: object[];
  @Input() bindProperty: string;
  @Input() label: string;
  @Input() multiSelect: boolean;
  @Input() currentlySelectedObject: object;
  @Input() currentlySelectedObjects: object[] = [];
  @Input() compareBy: string;
  @Input() typeahead: Subject<string>;
  @Input() disableMargin: boolean = false;
  @Input() disabled: boolean = false;
  @Input() areAllSelected: boolean = false;
  @Input() showOutlined: boolean = false;
  @Input() removeBorders: boolean = false;
  @Input() filterType: FilterTypes;

  @Output() objectSelected = new EventEmitter<object>();
  @Output() objectCleared = new EventEmitter<void>();
  @Output() applyChanges = new EventEmitter<object[]>();
  @Output() cancelChanges = new EventEmitter<void>();
  @Output() selectAll = new EventEmitter<void>();
  @Output() clearAll = new EventEmitter<void>();

  private readonly _debounceTime: number = 420;
  private readonly _mobileWidth: number = Constants.xs;
  private readonly _objectSelectedProperty: string = 'selected';
  private readonly _ngDropdownVisibleClass: string = 'ng-dropdown-visible';

  dropdownItemHeight = 32;

  expanded: boolean;
  filteredObjects: object[];
  searchValue: string = null;
  searchValueSubject: Subject<string> = new Subject();
  searchDropdownMobileVisible: boolean;
  cdkVirtualForViewChangeSubscription: Subscription;
  subscriptions = new Subscription();
  contentHeight = '200px';
  appliedFilters = [];
  newlySelected = [];
  focusedObjectIndex = -1;
  focusedObject: object;
  mobile = false;
  clearButtonDisabled = false;
  public readonly filterTypes = FilterTypes;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('keydown.enter', ['$event'])
  onEnterHandler() {
    if (this.focusedObject) {
      this.onObjectSelected(this.focusedObject);

      if (!this.multiSelect) {
        if (this.ngDropdownDirective) {
          this.ngDropdownDirective.onEscape();
        }

        this.focusedObjectIndex = -1;
        this.focusedObject = undefined;
      } else {
        setTimeout(() => {
          void this.textFieldComponent?.setFocus();
          this.changeDetectorRef.detectChanges();
        }, 0);
      }
    }
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUpHandler(event: KeyboardEvent) {
    this.focusObject(event);
  }

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDownHandler(event: KeyboardEvent) {
    this.focusObject(event);
  }

  ngOnInit() {
    this.mobile = window.innerWidth <= this._mobileWidth;
    if (this.mobile) {
      this.dropdownItemHeight = 44;
    }

    this.subscriptions.add(
      this.searchValueSubject.pipe(debounceTime(this._debounceTime)).subscribe((value) => {
        if (this.typeahead) {
          this.typeahead.next(value);
        } else {
          this.initFilteredObjects(this.searchValue);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.objects) {
      if (this.mobile) {
        this.contentHeight = '100%';
      } else {
        if (this.objects && this.objects.length < 4) {
          const height = 42 + (this.objects.length * 32);
          this.contentHeight = `${height}px`;
        } else {
          this.contentHeight = '200px';
        }
      }
    }

    if (this.multiSelect) {
      this.initFilteredObjects(this.searchValue);
    } else if (changes.objects || changes.currentlySelectedObject) {
      if (changes.currentlySelectedObject && changes.currentlySelectedObject.currentValue) {
        this.searchValue = this.removeHTMLTags(changes.currentlySelectedObject.currentValue[this.bindProperty].toString());
        this.initFilteredObjects(this.searchValue);
      } else {
        this.initFilteredObjects();
      }
    }
  }

  ngAfterViewInit() {
    if (!this.mobile) {
      this.subscriptions.add(
        this.ngDropdownDirective.dropdownToggled.subscribe((toggled) => {
          this.expanded = toggled;

          if (!toggled) {
            void this.textFieldComponent?.setBlur();
          }

          this.changeDetectorRef.detectChanges();
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onBlur() {
    this.expanded = false;
    this.changeDetectorRef.markForCheck();
  }

  onClick() {
    if (this.mobile) {
      if (this.searchDropdownMobileVisible) {
        this.expanded = true;
      } else {
        this.searchDropdownMobileVisible = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    if (this.multiSelect) {
      return;
    }

    if (
      this.currentlySelectedObject &&
      this.removeHTMLTags(this.currentlySelectedObject[this.bindProperty]) === this.searchValue
    ) {
      this.filteredObjects = this.objects.map((o) => {
        o[this._objectSelectedProperty] = undefined;

        return o;
      });
      this.changeDetectorRef.detectChanges();
    }

    if (this.cdkVirtualForViewChangeSubscription) {
      this.cdkVirtualForViewChangeSubscription.unsubscribe();
    }

    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualForViewChangeSubscription = this.cdkVirtualScrollViewport.scrolledIndexChange.subscribe(() => {
        this.reflectPositionDependingOnValue(
          this.currentlySelectedObject &&
            this.removeHTMLTags(this.currentlySelectedObject[this.bindProperty]) === this.searchValue
            ? undefined
            : this.searchValue
        );
        if (this.cdkVirtualForViewChangeSubscription) {
          this.cdkVirtualForViewChangeSubscription.unsubscribe();
        }
      });

      this.cdkVirtualScrollViewport.getElementRef().nativeElement.dispatchEvent(new Event('scroll'));
    }
  }

  onInput() {
    if (!this.expanded) {
      this.expanded = true;
      this.textFieldComponentElementRef.nativeElement.dispatchEvent(new Event('click'));
      void this.textFieldComponent?.setFocus();
    }
    this.changeDetectorRef.detectChanges();
    this.searchValueSubject.next(this.searchValue);

    this.checkIfAllAreSelected();
  }

  onClear(e: Event) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    const value = this.searchValue;

    this.searchValue = null;
    this.filteredObjects = this.objects.map((o) => {
      o[this._objectSelectedProperty] = undefined;

      return Object.assign({}, o);
    });
    this.changeDetectorRef.detectChanges();
    this.currentlySelectedObject = undefined;
    this.reflectPositionDependingOnValue(value);

    if (!this.multiSelect) {
      this.objectCleared.emit();
    }

    if (
      this.searchDropdownContent &&
      !this.searchDropdownContent.nativeElement?.parentElement?.classList.contains(this._ngDropdownVisibleClass)
    ) {
      this.textFieldComponentElementRef.nativeElement.dispatchEvent(new Event('click'));
    }

    if (!this.mobile) {
      this.ngDropdownDirective.updatePosition();
    }

    void this.textFieldComponent?.setFocus();

    this.checkIfAllAreSelected();
  }

  onIconsClick(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.textFieldComponentElementRef.nativeElement.dispatchEvent(new Event('click'));

    if (this.expanded) {
      void this.textFieldComponent?.setFocus();
    } else {
      void this.textFieldComponent?.setBlur();
    }
  }

  objectIsSelected(object: object): boolean {
    if (this.multiSelect) {
      return (
        object[this._objectSelectedProperty] || !!this.currentlySelectedObjects.find((o) => this.equalityPredicate(o, object))
      );
    } else {
      return object[this._objectSelectedProperty] || this.equalityPredicate(object, this.currentlySelectedObject);
    }
  }

  onObjectSelected(object: object) {
    object[this.bindProperty] = this.removeHTMLTags(object[this.bindProperty]);
    if (this.multiSelect) {
      void this.textFieldComponent?.setFocus();
      this.expanded = true;
    } else {
      this.searchValue = this.removeHTMLTags(object[this.bindProperty]);

      const isSameObject = this.equalityPredicate(object, this.currentlySelectedObject);
      if (isSameObject) {
        this.onClear(new Event('click'));
        return;
      }
    }

    if (this.multiSelect) {
      this.currentlySelectedObjects.push(object);
    } else {
      this.currentlySelectedObject = object;
    }
    this.changeDetectorRef.detectChanges();

    this.objectSelected.emit(object);

    if (!this.mobile) {
      if (!this.multiSelect) {
        if (this.ngDropdownDirective) {
          this.ngDropdownDirective.onEscape();
        }
      } else {
        this.ngDropdownDirective.updatePosition();
      }
    } else if (!this.multiSelect) {
      this.searchDropdownMobileVisible = false;
      if (this.ngDropdownDirective) {
        this.ngDropdownDirective.onEscape();
      }
    }

    this.setClearButtonState();
    this.checkIfAllAreSelected();
  }

  onSelectAll(): void {
    if (this.multiSelect) {
      this.selectAll.emit();
      this.checkIfAllAreSelected();
    }
  }

  onClearAll(): void {
    if (this.multiSelect) {
      this.clearAll.emit();
      this.checkIfAllAreSelected();
    }
  }

  onCancel() {
    this.searchDropdownMobileVisible = false;

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.cancelChanges.next();
    this.setClearButtonState();
    this.changeDetectorRef.markForCheck();
  }

  private equalityPredicate(a: object, b: object): boolean {
    if (!a || !b) {
      return false;
    }

    if (this.compareBy) {
      return a[this.compareBy] === b[this.compareBy];
    } else {
      return a === b || this.removeHTMLTags(a[this.bindProperty]) === this.removeHTMLTags(b[this.bindProperty]);
    }
  }

  private removeHTMLTags(text: string): string {
    const highlighted: RegExp = /<span class="highlighted">(.+?)<\/span>/g;
    const regexMatch: RegExpMatchArray = highlighted.exec(text);

    let replacement = '';

    if (regexMatch) {
      const [, group1] = Array.from(regexMatch);

      replacement = group1;
    }

    return text.replace(highlighted, replacement);
  }

  private setFilteredObjectsBySearchValue(value: string): void {
    value = value.toLowerCase();

    this.filteredObjects = this.objects
      .filter((object) => {
        const stripped = this.removeHTMLTags(object[this.bindProperty]).toLowerCase();

        return stripped.indexOf(value) !== -1;
      })
      .map((object) => {
        const stripped = this.removeHTMLTags(object[this.bindProperty]).toLowerCase();

        const startIndex = stripped.indexOf(value);
        const endIndex = startIndex + value.length;

        if (startIndex >= 0 && endIndex <= stripped.length) {
          let replacement = '<span class="highlighted">' + stripped.substring(startIndex, endIndex) + '</span>';

          if (startIndex > 0) {
            replacement = stripped.substring(0, startIndex) + replacement;
          }

          if (endIndex < stripped.length) {
            replacement = replacement + stripped.substring(endIndex);
          }

          const assignee = {};

          assignee[this.bindProperty] = object[this.bindProperty].replace(stripped, replacement);

          object[this._objectSelectedProperty] = false;
          object[this._objectSelectedProperty] = this.objectIsSelected(object);

          object = Object.assign({}, object, assignee);
        }

        return object;
      });

    this.changeDetectorRef.detectChanges();
  }

  private reflectPosition() {
    if (this.multiSelect) {
      return;
    }

    if (!this.currentlySelectedObject) {
      return;
    }

    const index: number = this.objects.findIndex((o) => this.equalityPredicate(o, this.currentlySelectedObject));

    this.cdkVirtualScrollViewport.scrollToIndex(index);
  }

  private reflectPositionDependingOnValue(value: string = undefined) {
    if (!this.cdkVirtualScrollViewport) {
      return;
    }

    if (value) {
      this.cdkVirtualScrollViewport.scrollToIndex(+false);
    } else {
      this.reflectPosition();
    }
  }

  private initFilteredObjects(value: string = undefined) {
    if (value) {
      this.setFilteredObjectsBySearchValue(value);
    } else {
      this.filteredObjects = this.objects.slice();
    }

    this.changeDetectorRef.detectChanges();

    this.reflectPositionDependingOnValue(value);
  }

  onApply() {
    this.applyChanges.next(this.currentlySelectedObjects);

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.searchDropdownMobileVisible = false;
  }

  onDropdownToggled(state: boolean) {
    if (this.multiSelect) {
      this.applyChanges.next(this.currentlySelectedObjects);
    }
    if (state === false && !this.multiSelect) {
      this.searchValue = this.currentlySelectedObject
        ? this.removeHTMLTags(this.currentlySelectedObject?.[this.bindProperty].toString())
        : '';
      this.initFilteredObjects(this.searchValue);
    }
    this.changeDetectorRef.detectChanges();
  }

  setClearButtonState() {
    if (this.multiSelect) {
      this.clearButtonDisabled = !this.currentlySelectedObjects || !this.currentlySelectedObjects.length;
    } else {
      this.clearButtonDisabled = !this.searchValue;
    }
  }

  focusObject(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 38: // arrow up
        if (this.focusedObjectIndex === -1) {
          this.focusedObjectIndex = 0;
        } else {
          this.focusedObjectIndex--;
        }
        this.downTraverse(this.filteredObjects.length);
        this.setFocused();
        this.scrollToFocusedObject();
        this.changeDetectorRef.detectChanges();

        break;

      case 40: // arrow down
        this.upTraverse(this.filteredObjects.length);
        this.focusedObjectIndex++;
        this.setFocused();
        this.scrollToFocusedObject();
        this.changeDetectorRef.detectChanges();

        break;
    }
  }

  setFocused() {
    this.focusedObject = this.filteredObjects[this.focusedObjectIndex];
  }

  isFocused(object: unknown) {
    return this.focusedObject === object;
  }

  scrollToFocusedObject() {
    if (this.cdkVirtualScrollViewport) {
      if (this.focusedObjectIndex > -1) {
        this.cdkVirtualScrollViewport.scrollToIndex(this.focusedObjectIndex - 2);
      }
    }
  }

  downTraverse(listLength: number) {
    if (this.focusedObjectIndex === -1) {
      this.focusedObjectIndex = listLength - 1;
    }
  }

  upTraverse(listLength: number) {
    if(listLength -1 <= this.focusedObjectIndex) {
      this.focusedObjectIndex = -1;
    }
  }

  private checkIfAllAreSelected(): void {
    if (!this.objects.length || this.searchValue) {
      this.areAllSelected = true;
    } else {
      this.areAllSelected = this.currentlySelectedObjects.length === this.objects.length;
    }
  }
}

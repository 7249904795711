import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { HubModalDataInterface } from '../interfaces/hub-modal-data.interface';
import { ObjectTypes } from '../../../enums/objectTypes';
import { Account } from '../../../models/account';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { RiskListViewModel } from 'src/app/modules/risk/models/RiskListViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { HasWritePermissionPipe } from '../../../pipes/has-write-permission.pipe';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { Employee } from '../../../models/employee';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { LocalisationService } from '../../../services/localisation.service';
import { ModalHeaderIconType } from '../../common/modal-header/modal-header.component';
import { DetailIconTypes } from '../../../types/DetailsIcon.types';
import { RouteHelper } from '../../../utilities/route.helper';
import { RiskTypes } from 'src/app/modules/risk/enums/riskTypes';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { IconTypes } from '../../../types/iconTypes';
import { RiskRagHelper } from '../../../utilities/risk-rag.utilities copy';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { AccountHubService } from 'src/app/modules/accountHub/services/account-hub.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';

@Component({
  selector: 'app-risk-details-modal',
  templateUrl: './risk-details-modal.component.html',
  styleUrl: './risk-details-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskDetailsModalComponent implements OnInit, HubModalDataInterface {
  @Input() object: RiskListViewModel;
  @Input() account: Account;
  @Input() allowedFilters: FilterViewModel[] = [];
  @Input() readonly = false;

  @Output() objectUpdated = new EventEmitter<RiskListViewModel>();

  public readonly T = T;
  public readonly iconType = ModalHeaderIconType.Rich;
  public readonly richIconType: DetailIconTypes = 'Risk';

  public loading = false;
  public canEdit = true;
  public isAdmin = false;

  public objectTypes = ObjectTypes;
  public objectType = ObjectTypes.Risk;
  public riskItem: RiskListViewModel;
  public filterTypes = FilterTypes;
  public ownerData: EmployeeViewModel;
  public ownerNames: string;
  public ragDescription: string;
  public baselineRagDescription: string;
  public svgIcon: string;
  public ragColor: string;
  public createdDate: string;
  public actionsBreakdown: { completed?: string; total?: string } = { completed: '0', total: '0'};
  public historyExpanded = false;
  public translatedImpact = 'Impact';

  private readonly subscriptions = new Subscription();
  private employee: Employee;
  private localisedRisk = '';
  private allowedOwnerFilters: FilterViewModel[] = [];


  constructor(
    public bsModalRef: BsModalRef,
    private readonly ts: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly hasWritePermissionPipe: HasWritePermissionPipe,
    private readonly localisationService: LocalisationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountHubService: AccountHubService
  ) {

  }

  //---------------------------------------------------------------------------------
  // Lifecycle Hooks
  //---------------------------------------------------------------------------------
  ngOnInit(): void {
    this.translatedImpact = this.localisationService.localiseFilterType(FilterTypes.Risk_Impact);

    if(this.object) {
      this.riskItem = JSON.parse(JSON.stringify(this.object)) as RiskListViewModel;
    }

    if (!this.account) {
      this.account = this.authenticationService.getCurrentAccount();
    }

    if (this.readonly) {
      this.canEdit = false;
    } else {
      this.checkUserWritePermission();
    }

    this.employee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.localisedRisk = this.localisationService.localiseObjectType(ObjectTypes.Risk);
    this.allowedOwnerFilters = this.allowedFilters.filter(f => f.filterType === FilterTypes.Owner);

    this.getOwnerData();
    this.setRAGInformation();
    this.setActionsBreakdown();

    this.createdDate = this.riskItem.filters.find(
      (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Created
    )?.filterValue as string;
  }

  //---------------------------------------------------------------------------------
  // Getters
  //---------------------------------------------------------------------------------
  get headerText(): string {
    return `${this.localisedRisk} Quick View`;
  }

  get riskDescriptionText(): string {
    return this.riskItem.filters.find(f => f.filterType === FilterTypes.Description)?.filterValue as string;
  }

  //---------------------------------------------------------------------------------
  // Public methods
  //---------------------------------------------------------------------------------
  public close() {
    this.bsModalRef.hide();
  }

  public navigateToDetaislPage() {
    const route = [
      this.account.url,
      RouteHelper.getRoute(this.account.accountUID, this.objectType, RiskTypes.Risk),
      this.riskItem.id
    ].join('');

    window.open(route, '_blank');
  }

  public getOwnerData(): void {
    const ownerId = +this.riskItem.filters.find(f => f.filterType === FilterTypes.Owner)?.filterValue;
    this.ownerNames = this.allowedOwnerFilters.find((f) => f.filterValue === ownerId)?.filterText;

    this.accountHubService.getHubChildEmployees().subscribe((employees) => {
      this.ownerData = employees.find((e) => e.id === ownerId);
      this.changeDetectorRef.detectChanges();
    });
  }

  public onFiltersUpdated(filters: FilterViewModel[]) {
    this.saveFiltersChanges(filters);
  }

  public onTitleUpdate(title: string) {
    this.riskItem.title = title;

    const titleFilter = this.riskItem.filters.find((f) => f.filterType === FilterTypes.Title);
    if (titleFilter) {
      titleFilter.filterValue = title;
      titleFilter.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([titleFilter]);
  }

  public onHeadlineStatusUpdated(item: RiskListViewModel) {
    this.riskItem.headlineStatus = item.headlineStatus;
    this.riskItem.headlineStatusUpdated = new Date().toJSON();
    const filter = this.riskItem.filters.find((f) => f.filterType === FilterTypes.Headline_Status);
    if (filter) {
      filter.filterValue = item.headlineStatus;
      filter.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([filter]);
  }

  public onDescriptionUpdate(description: string) {
    const matching = this.riskItem?.filters?.find((f) => f.filterType === FilterTypes.Description);
    if (matching) {
      matching.filterValue = description;
      matching.filterText = description;
      matching.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([matching]);
  }

  public onDueDateChange(dueDate: Date) {

  }

  public onItemIndexClicked(index: number) {
    if (index === 1) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  //---------------------------------------------------------------------------------
  // Private methods
  //---------------------------------------------------------------------------------
  private checkUserWritePermission(): void {
    this.canEdit = this.hasWritePermissionPipe.transform(this.riskItem, ModuleTypes.Risk, FilterTypes.Risk);
  }

  private setRAGInformation() {
    this.ragDescription = this.localisationService.localiseFilterValueByFilterType(
      this.riskItem.rag,
      this.filterTypes.Risk_RAG
    ) as string;

    this.ragColor = RiskRagHelper.getRAGColourHexFromValue(this.riskItem.rag);
    this.svgIcon = IconUtilities.getSvgForIconType(IconTypes.Risk, 16, false, '#FFFFFF');

    this.changeDetectorRef.markForCheck();
  }

  public setActionsBreakdown(): void {
    const actionBreakdownFilter = this.riskItem.filters.find(
      (f) => f.filterType === FilterTypes.Risk_Action_Breakdown
    );

    if(actionBreakdownFilter) {
      [this.actionsBreakdown.completed, this.actionsBreakdown.total] = actionBreakdownFilter.filterText.split('/');
    }
  }

  private saveFiltersChanges(filters: FilterViewModel[]) {
    const onlyFiltersWithActions = filters.filter((a) => a.filterAction !== FilterActionTypes.None);

    this.accountHubService
      .updateObject(this.riskItem.id, this.riskItem.accountId, this.objectType, onlyFiltersWithActions)
      .subscribe((res) => {
        const changes = res.changes as unknown as FilterViewModel[];
        this.riskItem.filters = FilterUtilities.ApplyFilterActions(this.riskItem.filters, onlyFiltersWithActions);
        this.riskItem.filters = FilterUtilities.ApplyFilterActions(this.riskItem.filters, changes);
        this.objectUpdated.emit(this.riskItem);
      });
  }

}

import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export enum CheckboxStates {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate'
}
@Component({
  selector: 'app-checkbox-with-label',
  templateUrl: './checkbox-with-label.component.html',
  styleUrls: ['./checkbox-with-label.component.scss'],
})
export class CheckboxWithLabelComponent implements OnInit, OnChanges {
  @Input() intialState: CheckboxStates;
  @Input() label: string;
  @Input() indeterminate: boolean;
  @Input() disabled: boolean;

  @Output() clicked = new EventEmitter<CheckboxStates>();
  state: CheckboxStates;
  isIndeterminate: boolean;
  public customCheckboxId = uuidv4() as string;

  ngOnInit(): void {
    this.state = this.intialState;
    if (this.intialState === CheckboxStates.Indeterminate) {
      this.isIndeterminate = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.intialState && changes.intialState.currentValue) {
      this.state = changes.intialState.currentValue as CheckboxStates;
    }
  }

  getState() {
    if (this.state === CheckboxStates.Checked) {
      return true;
    }

    return false;
  }

  onClick() {
    if (this.state === CheckboxStates.Unchecked) {
      if (this.indeterminate) {
        this.state = CheckboxStates.Indeterminate;
        this.isIndeterminate = true;
      } else {
        this.state = CheckboxStates.Checked;
      }
    } else if (this.state === CheckboxStates.Indeterminate) {
      this.isIndeterminate = false;
      this.state = CheckboxStates.Checked;
    } else if (this.state === CheckboxStates.Checked) {
      if (this.indeterminate) {
        this.state = CheckboxStates.Unchecked;
        this.isIndeterminate = false;
      } else {
        this.state = CheckboxStates.Unchecked;
      }
    }
    this.clicked.emit(this.state);
  }

  public static toBooleanState(state: string, treatIndeterminateStateAsChecked: boolean = false): boolean {
    if (state === CheckboxStates.Unchecked) return false;
    if (state === CheckboxStates.Indeterminate) {
      return treatIndeterminateStateAsChecked;
    }

    return true;
  }
}

<div *ngIf="isSettingsRoute" class="child-setting-header details-heading mb-2">
  <div class="title-wrapper text-truncate mr-2">
    <app-back-button [url]="':accountId/settings/documents-manager' | url"></app-back-button>
    <div class="child-setting-header-title">
      {{ T.settings.document_manager | translate }}<i class="material-symbols-outlined">keyboard_arrow_right</i>
      {{ getTitle() }}
    </div>
  </div>
</div>

<ng-container *ngIf="!loading">
  <div *ngIf="!documentCategoriesRoute && objects" class="content-wrapper">
    <input
      #file
      type="file"
      [name]="'file-' + objectId"
      class="inputfile"
      (change)="onSelectFile(file.files)"
      (click)="$event.target.value = null"
      multiple
    />
    <div class="documents-list-wrapper">
      <div class="attachments-list-header-wrapper mb-4">
        <app-attachments-list-header
          (onAttachmentViewChanged)="selectAttachmentsView($event)"
          (onAttachmentsTypeChanged)="attachmentsTypeChanged($event)"
          (onAddLink)="addLink()"
          (onTakePhoto)="takePhoto()"
          (onFileUpload)="openFileUpload()"
          (onCategoryOptionChanged)="onCategoryOptionChange($event)"
          [isNativePlatform]="isNativePlatform"
          [isDetailsPage]="isDetailsPage"
          [isInAccordion]="isInAccordion"
          [showListBoardButtons]="showListBoardButtons"
          [documentCategories]="documentCategories"
          [showUploadButton]="canEdit"
          [showCategoryDropdowns]="showCategoryDropdowns"
        ></app-attachments-list-header>
      </div>
      <ng-container *ngIf="selectedListView === attachmentsListViewTypes.Board">
        <app-attachments-board-view
          [documents]="objects"
          [accountId]="accountId"
          [uploadViewModel]="uploadViewModel"
          (onAttachmentDownload)="attachmentDownload($event)"
          (onAttachmentDelete)="deleteDocument($event)"
          (onAttachmentLinkClicked)="attachmentLinkClicked($event)"
        ></app-attachments-board-view>
      </ng-container>
      <ng-container *ngIf="selectedListView === attachmentsListViewTypes.List">
        <app-attachments-list-view
          [accountId]="accountId"
          [documents]="objects"
          [uploadViewModel]="uploadViewModel"
          [canDelete]="canEdit"
          [objectType]="objectType"
          [objectId]="objectId"
          [isModal]="isModal"
          [documentCategories]="documentCategories"
          (attachmentDeleted)="deleteDocument($event)"
          (attachmentDownloaded)="attachmentDownload($event)"
          (onAttachmentLinkClicked)="attachmentLinkClicked($event)"
          (editAttachment)="editDocument($event)"
        ></app-attachments-list-view>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!objects && canEdit">
    <app-upload-container
      [globalObjectId]="0"
      (onFilesAdded)="addFiles($event)"
      (onLinkAdded)="addLink()"
    ></app-upload-container>
  </ng-container>

  <div *ngIf="documentCategoriesRoute" class="content-wrapper">
    <div class="documents-list-wrapper">
      <div style="display: flex; position: relative" class="header">
        <app-list-header
          [showRAGBreakdown]="false"
          [searchPlaceholder]="T.common.search_item | translate: { item: T.common.category.one | translate }"
          (onSearch)="onCategorySearch($event)"
        >
          <app-button-rectangle *ngIf="canEdit"
            (click)="addDocumentCategory()"
            [text]="T.common.add_item | translate: { item: T.defaultLocalizations.document_category.one | translate }"
            size="small"
            icon="add"
            buttonTheme="white"
            class="mr-8"
          ></app-button-rectangle>
        </app-list-header>
        <app-button-rectangle *ngIf="canEdit"
          [icon]="'delete'"
          [ngClass]="{ disabled: !documentCategoryDeleteButtonEnabled }"
          [disabled]="!documentCategoryDeleteButtonEnabled "
          size="small"
          buttonTheme="white"
          class="mt-8"
          [text]="T.common.delete_item | translate: { item: T.common.category.one | translate }"
          (click)="$event.stopPropagation()"
          (click)="deleteCategory(selectedCategoriesArray)"
        ></app-button-rectangle>
      </div>
      <ng-container *ngIf="documentObjects">
        <app-responsive-table
          [objects]="documentObjects"
          [selectedObjects]="selectedCategoriesArray"
          [tableHeaders]="categoriesTableHeaders()"
          [wholeRowSelection]="false"
          [adoptParentHeight]="false"
          [useSearch]="false"
          [searchSubject]="searchSubject"
          [selectableObjects]="canEdit"
          (objectsSelected)="selectedCategories($event)"
          (objectClicked)="onCategoryClicked($event)"
        ></app-responsive-table>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="loading">
  <app-loading></app-loading>
</ng-container>

<!-- Templates -->
<ng-template #uploadButtonTemplate>
  <div class="dropdown-options-container">
    <div class="dropdown-option" *ngIf="isNativePlatform" (click)="takePhoto()">
      {{ T.common.take_a_photo | translate }}
    </div>
    <div class="dropdown-option" (click)="openFileUpload()">{{ T.common.choose_a_file | translate }}</div>
    <div class="dropdown-option" (click)="addLink()">{{ T.common.add_link | translate }}</div>
  </div>
</ng-template>


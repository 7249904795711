import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { IncidentDetailsItemViewModel } from '../../incidents/viewModels/incidentDetailsItemViewModel';
import { FilterActionTypes } from '../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { UpdateTypes } from '../enums/updateTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { ItemSubscriptionViewModel } from '../models/itemSubscriptionViewModel';
import { SubscriberViewModel } from '../models/subscriberViewModel';
import { HasWritePermissionPipe } from '../pipes/has-write-permission.pipe';
import { AlertService } from '../services/alert.service';
import { AuthenticationService } from '../services/authentication.service';
import { ConfirmationService } from '../services/confirmation.service';
import { LocalisationService } from '../services/localisation.service';
import { PinningService } from '../services/pinning.service';
import { SubscriptionService } from '../services/subscription.service';
import { ModalUtilityService } from '../services/utilities/modals-utilities.service';
import { EmployeeUtil } from '../utilities/employee.utilities';
import { FilterUtilities } from '../utilities/filter.utilities';
import { DocumentViewModel } from '../viewModels/documents/documentViewModel';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { SubscriberUserGroupViewModel } from '../models/subscriberUserGroupViewModel copy';
import { Account } from '../models/account';

export class DetailsViewBaseClass {
  public objectType: ObjectTypes;
  public loading: boolean;
  public localisedItem: string;
  public localisedItems: string;
  public bsModalRef: BsModalRef;
  public isExpanded: boolean;
  public isAdmin: boolean;
  public currentDetailsItem: any;
  public isArchived: boolean;
  public isSubscribed: boolean;
  public selectedHeaderTabId: string;
  public selectedFooterTabId: string;
  public selectedAccordionItemId: string;
  public subscribers: SubscriberViewModel[] = [];
  public subscribersGroup: SubscriberUserGroupViewModel[] = [];
  public attachments: DocumentViewModel[] = [];
  public attachmentsCount: number = 0;
  public tabIds: string[];
  public accordionItemIds: string[];
  public historyEntriesCount: number;
  public updateType: UpdateTypes;
  public moduleTypes = ModuleTypes;
  protected employee: Employee;
  protected subscriptions = new Subscription();
  public readonly T = T;

  public viewDetailsText = 'View details';
  public addCommentText = 'Add comment';
  public pinToMyTrackText = 'Pin this item to MyTrack';
  public unpinFromMyTrackText = 'Unpin this item from MyTrack';
  public subscribeToUpdatesText = 'Subscribe to updates';
  public unsubscribeText = 'Unsubscribe';
  public archiveText = 'Archive';
  public unarchiveText = 'Unarchive';
  public deleteText = 'Delete';
  public localisedItemUpdatedText = 'Item updated';
  public confirmArchiveText = 'Are you sure you wish to archive this item?';
  public confirmUnarchiveText = 'Are you sure you wish to unarchive this item?';
  public itemArchivedText = 'Item archived';
  public itemUnarchivedText = 'Item unarchived';
  public confirmDeleteText = 'Are you sure you wish to delete this item?';
  public itemDeletedText = 'Item deleted';
  public itemUpdatedText = 'Item updated';
  protected activeLightUpdate: boolean = false;
  subscribersCount: number = 0;
  subscribersGroupCount: number = 0;
  linkedItemsCount: number = 0;
  locationFieldsCount: number = 0;
  account: Account;
  /**
   * This method should be overwrited
   * When activeLightUpdate is true, consider using saveFiltersChanges instead of this method
   */
  saveChanges() {
    throw new Error('Base Method called');
  }

  /**
   * This method should be overwrited
   * This method should be used only when activeLightUpdate is true. Which means that only the filters will be updated instead of the whole VM
   * @param filtersToUpdate Filters to update
   */
  saveFiltersChanges(filtersToUpdate: FilterViewModel[]) {
    throw new Error('Base Method called');
  }

  constructor(
    objectType: ObjectTypes,
    protected readonly localisationService: LocalisationService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly bsModalService: BsModalService,
    protected readonly utilityModalService: ModalUtilityService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly subscriptionService: SubscriptionService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly alertService: AlertService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly router: Router,
    protected readonly route: ActivatedRoute,
    protected readonly hasWritePermissionPipe: HasWritePermissionPipe,
    protected readonly pinningService: PinningService,
    protected readonly translateService: TranslateService
  ) {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.objectType = objectType;
    this.localisedItem = this.localisationService.localiseObjectType(objectType);
    this.localisedItems = this.localisationService.localiseObjectType(objectType, true);
    this.translateTexts();
  }

  get accountId() {
    return this.employee.accountId;
  }

  initRouteFragmentSubscription() {
    this.subscriptions.add(
      this.route.fragment.subscribe((fragment: string) => {
        if (fragment) {
          this.onFooterTabSelect(fragment);
        }
      })
    );
  }

  toggleDetailsState(state: boolean) {
    this.isExpanded = state;
    this.changeDetectorRef.markForCheck();
  }

  initIsArchived() {
    if (!this.currentDetailsItem.filters || !this.currentDetailsItem.filters.length) {
      this.isArchived = false;
    } else {
      this.isArchived = this.currentDetailsItem.filters.some(
        (f) => f.filterType == FilterTypes.Archived && JSON.parse(f.filterValue.toLowerCase()) == true
      );
    }
  }

  initIsSubscribed(): void {
    if (!this.currentDetailsItem.filters || !this.currentDetailsItem.filters.length) {
      this.isSubscribed = false;
    } else {
      const subscription = this.currentDetailsItem.filters.find(
        (f) => f.filterType == FilterTypes.Subscriber && f.filterValue.toString() == this.employee.id.toString()
      );
      subscription ? (this.isSubscribed = true) : (this.isSubscribed = false);
    }
  }

  onFooterTabSelect(id: string) {
    this.selectedFooterTabId = id;
  }

  onHeaderTabSelect(id: string) {
    this.selectedHeaderTabId = id;
  }

  updateRef(refcode: string) {
    this.currentDetailsItem.refCode = refcode;

    const refCodeFilter = this.currentDetailsItem.filters.find((s) => s.filterType == FilterTypes.Ref_Code) as FilterViewModel;
    if (refCodeFilter) {
      refCodeFilter.filterValue = refcode;
      refCodeFilter.filterAction = FilterActionTypes.Update;
    }

    if(this.activeLightUpdate){
      this.saveFiltersChanges([refCodeFilter]);
    }else {
      this.saveChanges();
    }
  }

  updateDescription(description: string) {
    this.currentDetailsItem.description = description;

    const matching = this.currentDetailsItem?.filters?.find((f) => f.filterType == FilterTypes.Description);
    if (matching) {
      matching.filterValue = description;
      matching.filterText = description;
      matching.filterAction = FilterActionTypes.Update;
    }

    if(this.activeLightUpdate){
      this.saveFiltersChanges([matching]);
    }else {
      this.saveChanges();
    }
  }

  openShareModal() {
    if (this.objectType != ObjectTypes.IncidentItem) {
      this.utilityModalService.openShareModal(this.currentDetailsItem.id, this.objectType, this.currentDetailsItem.title, this.localisedItem);
    } else {
      const inc = this.currentDetailsItem as IncidentDetailsItemViewModel;
      this.utilityModalService.openShareModal(
        this.currentDetailsItem.id,
        this.objectType,
        this.currentDetailsItem.title,
        this.localisedItem,
        (this.currentDetailsItem as IncidentDetailsItemViewModel).incidentItemType
      );
    }
  }

  openAddUpdateModal() {
    this.utilityModalService.openAddUpdateModal(this.currentDetailsItem.id, this.objectType, this.updateType);
  }

  onSubscribersChanged(subscribers: SubscriberViewModel[]) {
    this.subscribers = subscribers;
    this.subscribersCount = this.subscribers.length;
    this.currentDetailsItem.filters = this.currentDetailsItem.filters.filter((f) => f.filterType !== FilterTypes.Subscriber);
    this.subscribers.forEach((s) => {
      this.currentDetailsItem.filters.push(
        FilterUtilities.GenerateFilter(FilterTypes.Subscriber, s.employeeId.toString(), s.firstName + ' ' + s.surname)
      );
    });
    this.changeDetectorRef.detectChanges();
  }

  onSubscribersGroupChanged(subscribersGroup: SubscriberUserGroupViewModel[]) {
    this.subscribersGroup = subscribersGroup;

    this.currentDetailsItem.filters = this.currentDetailsItem.filters.filter((f) => f.filterType !== FilterTypes.Subscriber_Group);
    this.subscribersGroup.forEach((group) => {
      this.currentDetailsItem.filters.push(
        FilterUtilities.GenerateFilter(FilterTypes.Subscriber_Group, group.userGroupId.toString(), group.title)
      );
    });
    this.subscribersGroupCount = this.subscribersGroup.length;
    this.changeDetectorRef.detectChanges();
  }

  onDocumentsChanged(documents: DocumentViewModel[]) {
    if (documents) {
      this.attachments = documents;
      this.attachmentsCount = documents.length;
    }
  }

  subscribe(subscribe: boolean) {
    const itemSubscriptionViewModel = new ItemSubscriptionViewModel();
    itemSubscriptionViewModel.accountId = this.employee.accountId;
    itemSubscriptionViewModel.employeeId = this.employee.id;
    itemSubscriptionViewModel.globalObjectId = this.currentDetailsItem.id;
    itemSubscriptionViewModel.globalObjectType = this.objectType;

    if (subscribe) {
      this.subscriptions.add(
        this.subscriptionService.addItemSubscription(itemSubscriptionViewModel).subscribe((res) => {
          void this.alertService.success(`You have successfully subscribed for this ${this.localisedItem}.`);
          this.currentDetailsItem.filters.push(
            FilterUtilities.GenerateFilter(FilterTypes.Subscriber, res.employeeId, res.firstName + ' ' + res.surname)
          );
          this.objectEventEmitters.broadcastObjectSubscribedTo(
            this.currentDetailsItem.id,
            this.objectType,
            itemSubscriptionViewModel
          );
          this.initIsSubscribed();
          this.changeDetectorRef.markForCheck();
        })
      );
    } else {
      this.subscriptions.add(
        this.subscriptionService.removeItemSubscription_new(this.currentDetailsItem.id, this.objectType).subscribe(() => {
          void this.alertService.success(`You have successfully unsubscribed from this ${this.localisedItem}.`);
          const matchingFilter = this.currentDetailsItem.filters.find(
            (f) => f.filterType === FilterTypes.Subscriber && f.filterValue.toString() == this.employee.id.toString()
          );
          if (matchingFilter) {
            this.currentDetailsItem.filters.splice(this.currentDetailsItem.filters.indexOf(matchingFilter), 1);
          }
          this.objectEventEmitters.broadcastObjectUnsubscribedFrom(
            this.currentDetailsItem.id,
            this.objectType,
            itemSubscriptionViewModel
          );
          this.initIsSubscribed();
          this.changeDetectorRef.markForCheck();
        })
      );
    }
  }

  validateUrl(id: string): boolean {
    let isValidUrl = false;
    if (this.router.url.endsWith(id)) {
      isValidUrl = true;
    }

    if (!isValidUrl) {
      if (this.tabIds) {
        if (this.tabIds.findIndex((tabId) => this.router.url.endsWith(id + '#' + tabId)) > -1) {
          isValidUrl = true;
        }
      }
    }

    if(!isValidUrl) {
      if(this.accordionItemIds && this.accordionItemIds.findIndex((item) => this.router.url.endsWith(id + '#' + item)) > -1) {
        isValidUrl = true;
      }
    }

    return isValidUrl;
  }

  onHistoryEntriesUpdate(count: number) {
    this.historyEntriesCount = count;
  }

  updateTitle(newTitle: string): void {
    this.currentDetailsItem.title = newTitle;
    const titleFilter = this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Title);

    if (titleFilter) {
      titleFilter.filterValue = newTitle;
      titleFilter.filterAction = FilterActionTypes.Update;
    }

    if(this.activeLightUpdate){
      this.saveFiltersChanges([titleFilter]);
    }else {
      this.saveChanges();
    }
  }

  updateFilters(filters: FilterViewModel[]) {
    if (this.activeLightUpdate) {
      const removedFilters = this.currentDetailsItem.filters.filter((a) => filters.findIndex((b) => b.id === a.id) === -1) as FilterViewModel[];
      removedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Remove));

      const addedFilters = filters.filter((a) => this.currentDetailsItem.filters.findIndex((b) => b.id === a.id) === -1);
      addedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Add));
      this.saveFiltersChanges([...addedFilters, ...removedFilters])
    } else {
      this.currentDetailsItem.filters = filters;
      this.saveChanges();
    }

  }

  pinItem() {
    this.pinningService.pinItem(this.objectType, this.currentDetailsItem.id).subscribe((res) => {
      this.currentDetailsItem.isPinned = true;
      void this.alertService.success(`${this.localisedItem} pinned to MyTrack.`);
      this.objectEventEmitters.broadcastObjectPinned(this.currentDetailsItem.id, this.objectType, this.currentDetailsItem);
      this.objectEventEmitters.broadcastObjectUpdated(this.currentDetailsItem.id, this.objectType, this.currentDetailsItem);
    });
  }
  unpinItem() {
    this.pinningService.unpinItem(this.objectType, this.currentDetailsItem.id).subscribe((res) => {
      this.currentDetailsItem.isPinned = false;
      void this.alertService.success(`${this.localisedItem} unpinned from MyTrack.`);
      this.objectEventEmitters.broadcastObjectUnpinned(this.currentDetailsItem.id, this.objectType, this.currentDetailsItem);
      this.objectEventEmitters.broadcastObjectUpdated(this.currentDetailsItem.id, this.objectType, this.currentDetailsItem);
    });
  }

  public translateTexts(): void {
    this.viewDetailsText = this.translateService.instant(T.common.view_details);
    this.addCommentText = this.translateService.instant(T.common.add_comment);
    this.pinToMyTrackText = this.translateService.instant(T.common.pin_to_mytrack);
    this.unpinFromMyTrackText = this.translateService.instant(T.common.unpin_from_mytrack);
    this.subscribeToUpdatesText = this.translateService.instant(T.common.subscribe_to_updates);
    this.unsubscribeText = this.translateService.instant(T.common.unsubscribe);
    this.archiveText = this.translateService.instant(T.common.archive);
    this.unarchiveText = this.translateService.instant(T.common.unarchive);
    this.deleteText = this.translateService.instant(T.common.delete);
    this.localisedItemUpdatedText = this.translateService.instant(T.common.item_updated, { item: this.localisedItem }) + '.';
    this.confirmArchiveText = this.translateService.instant(T.common.prompt_confirm_archive_item, { item: this.localisedItem });
    this.confirmUnarchiveText = this.translateService.instant(T.common.prompt_confirm_unarchive_item, {
      item: this.localisedItem,
    });
    this.itemArchivedText = this.translateService.instant(T.common.archived_item_successfully, { item: this.localisedItem });
    this.itemUnarchivedText = this.translateService.instant(T.common.unarchived_item_successfully, { item: this.localisedItem });
    this.confirmDeleteText = this.translateService.instant(T.common.confirm_delete_item, { item: this.localisedItem });
    this.itemDeletedText = this.translateService.instant(T.common.item_deleted, { item: this.localisedItem });
    this.itemUpdatedText = this.translateService.instant(T.common.item_updated, { item: this.localisedItem });
  }
}

import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressbar  } from 'ngx-progressbar';
import { StorageModule } from '@ngx-pwa/local-storage';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutes } from './app.routes';
import { ServerErrorInterceptor } from './modules/shared/interceptors/serverErrorInterceptor';
import { AuthModule } from './modules/auth/auth.module';
import { unAuthorisedComponent } from './unauthorised/unauthorised.component';
import { CustomToastrComponent } from './modules/shared/components/common/custom-toastr/custom-toastr.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { SignOidcComponent } from './modules/auth/signOidc-component/sign-oidc.component';
import { IonicModule } from '@ionic/angular';
import { WtStorageService } from './modules/shared/services/wt-storage.service';
import { QuicklinkModule } from 'ngx-quicklink';
import { AzureDebugInterceptor } from './modules/shared/interceptors/azure-debug.interceptor';
import { UserAccountSelectComponent } from './modules/account-selection/components/user-accounts/user-accounts.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NoAccessComponent } from './no-access/no-access.component';
import { MapsLoaderService } from './modules/shared/services/mapsLoader.service';
import { CoreModule } from './modules/core/core.module';

defineLocale('engb', enGbLocale);

export function onAppInit(storage: WtStorageService): () => Promise<any> {
  return storage.loadAllDataInMemoryFromStorage;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/translations/', '.json');
}

export function googleMapsLoader(mapsLoaderService: MapsLoaderService) {
  return () => {
      return new Promise<void>((resolve) => {
        mapsLoaderService.loadGoogleMapsAPIs().subscribe(() => {
          resolve();
        });
      });
  };
}

const APP_MODULES = [CoreModule.forRoot(), SharedModule.forRoot()];

@NgModule({ declarations: [AppComponent, unAuthorisedComponent, UserAccountSelectComponent, SignOidcComponent, AccessDeniedComponent, NoAccessComponent],
    bootstrap: [AppComponent], imports: [AppRoutes,
        BrowserModule,
        HammerModule,
        QuicklinkModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            toastComponent: CustomToastrComponent,
            autoDismiss: true,
            maxOpened: 1,
        }),
        NgProgressbar,
        // WETRACK MODULES
        AuthModule.forRoot(),
        ...APP_MODULES,
        StorageModule.forRoot({ IDBNoWrap: true }),
        IonicModule.forRoot({
            scrollAssist: true,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'en',
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: onAppInit,
            deps: [WtStorageService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AzureDebugInterceptor,
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: googleMapsLoader,
            multi: true,
            deps: [MapsLoaderService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}

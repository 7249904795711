import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObjectTypes } from '../../../enums/objectTypes';
import { Account } from '../../../models/account';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { HubModalDataInterface } from '../interfaces/hub-modal-data.interface';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationService } from '../../../services/localisation.service';
import { T } from 'src/assets/i18n/translation-keys';
import { Subscription } from 'rxjs';
import { IncidentListItemViewModel } from 'src/app/modules/incidents/viewModels/incidentListItemViewModel';
import { ModalHeaderIconType } from '../../common/modal-header/modal-header.component';
import { DetailIconTypes } from '../../../types/DetailsIcon.types';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RouteHelper } from '../../../utilities/route.helper';
import { HasWritePermissionPipe } from '../../../pipes/has-write-permission.pipe';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { IncidentsManager } from '../../../managers/incidents.manager';
import { SeverityViewModel } from '../../../models/severityViewModel';
import { IncidentStatusUpdateViewModel } from '../../../models/incidentStatusUpdate';
import { IncidentStatuses } from 'src/app/modules/incidents/enums/incidentStatuses';
import { Colors } from '../../../enums/colors';
import * as moment from 'moment';
import { NgRangeDatesOutput } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { EditableFieldTypes } from '../../../enums/editableFieldTypes';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { Employee } from '../../../models/employee';
import { AccountHubService } from 'src/app/modules/accountHub/services/account-hub.service';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';

@Component({
  selector: 'app-incident-details-modal',
  templateUrl: './incident-details-modal.component.html',
  styleUrl: './incident-details-modal.component.scss'
})
export class IncidentDetailsModalComponent implements OnInit, HubModalDataInterface {
  @Input() object: IncidentListItemViewModel;
  @Input() account: Account;
  @Input() allowedFilters: FilterViewModel[] = [];
  @Input() readonly = false;

  @Output() objectUpdated = new EventEmitter<IncidentListItemViewModel>();

  public readonly T = T;
  public readonly iconType = ModalHeaderIconType.Rich;
  public readonly richIconType: DetailIconTypes = 'IncidentItem';
  public filterTypes = FilterTypes;
  public objectTypes = ObjectTypes;
  public dayDifference: number = 0;
  public objectType: ObjectTypes = ObjectTypes.IncidentItem;
  public editableFieldTypes = EditableFieldTypes;
  protected employee: Employee;
  public incidentItem: IncidentListItemViewModel;

  public loading = false;
  public canEdit = true;
  public isAdmin = false;
  public hasIncompleteStarredChecklists = false;
  public readonly incidentStatusesEnum = IncidentStatuses;
  public historyExpanded = false;

  private readonly subscriptions = new Subscription();
  private localisedIncident = '';
  private allowedOwnerFilters: FilterViewModel[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ts: TranslateService,
    private readonly localisationService: LocalisationService,
    private readonly authenticationService: AuthenticationService,
    private readonly hasWritePermissionPipe: HasWritePermissionPipe,
    public readonly incidentsManager: IncidentsManager,
    private readonly accountHubService: AccountHubService,
  ) {

  }

  ngOnInit(): void {
    if(this.object) {
      this.incidentItem = JSON.parse(JSON.stringify(this.object)) as IncidentListItemViewModel;
    }

    if (!this.account) {
      this.account = this.authenticationService.getCurrentAccount();
    }

    if (this.readonly) {
      this.canEdit = false;
    } else {
      this.checkUserWritePermission();
    }

    this.employee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.localisedIncident = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem);
    this.dayDifference = moment().diff(this.incidentItem.startTime, 'days');

    this.allowedOwnerFilters = this.allowedFilters.filter(f => f.filterType === FilterTypes.Owner);
  }

  get headerText(): string {
    return `${this.localisedIncident} Quick View`;
  }

  get severityCheckpoint(): SeverityViewModel {
    return this.incidentsManager.getSeverityByValue(this.incidentItem.severity);
  }

  public get openedDaysColor(): string {
    if (this.dayDifference <= 1) {
      return Colors.Black;
    } else if (this.dayDifference <= 7) {
      return Colors.Amber;
    } else {
      return Colors.Red;
    }
  }

  get getDayDifferenceText(): string {
    let dayDifferenceText: string;
    if (this.incidentItem.startTime) {
      if (this.dayDifference === 0) {
        dayDifferenceText = `${this.ts.instant(T.common.opened_today)}`;
      } else {
        dayDifferenceText = `${this.ts.instant(T.common.open_for)} ${Math.abs(this.dayDifference)} ${this.dayDifference === 1 ? this.ts.instant(T.calendar.day.one) : this.ts.instant(T.calendar.day.many)}`;
      }
    }

    return dayDifferenceText;
  }

  get ownerNames(): string {
    return this.incidentItem.filters
      .filter(f => f.filterType === FilterTypes.Owner)
      .map(f => this.allowedOwnerFilters.find(af => af.filterValue === f.filterValue).filterText)
      .join(', ');
  }

  public close() {
    this.bsModalRef.hide();
  }

  public onItemIndexClicked(index: number) {
    if (index === 1) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  public onSeverityCheckpointSelected(severityCheckpoint: SeverityViewModel) {
    const incidentSeverity = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Incident_Severity);
    incidentSeverity.filterValue = severityCheckpoint.severity;
    incidentSeverity.filterAction = FilterActionTypes.Update;

    this.incidentItem.severity = severityCheckpoint.severity;
    this.saveFiltersChanges([incidentSeverity]);
  }

  public onStatusChanged(status: IncidentStatusUpdateViewModel) {
    const currentStatusFilter = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Incident_Status);
    const statusFilter = status.filters.find((f) => f.filterType === FilterTypes.Incident_Status);
    statusFilter.filterAction = FilterActionTypes.Update;
    if(!statusFilter){
      return
    }
    let filtersToUpdate = [statusFilter];
    const closedDate = moment().toISOString();
    if (
      statusFilter.filterValue as string !== IncidentStatuses.Draft.toString() &&
      statusFilter.filterValue as string !== currentStatusFilter.filterValue as string
    ) {
      this.incidentItem.status = statusFilter.filterValue as IncidentStatuses;

      if (statusFilter.filterValue.toString() === IncidentStatuses.Closed.toString()) {
        filtersToUpdate = this.setClosedFilter(filtersToUpdate, closedDate);
        this.incidentItem.closed = closedDate;
      } else {
        filtersToUpdate = this.setClosedFilter(filtersToUpdate, '');
      }

      if (this.incidentItem.status === IncidentStatuses.Closed && !this.incidentItem.endTime) {
        this.incidentItem.endTime = closedDate;

        let dueDateFilter = this.incidentItem.filters.find(
          (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Time
        );

        if (!dueDateFilter) {
          dueDateFilter = FilterUtilities.GenerateFilter(
            FilterTypes.Date,
            closedDate,
            '',
            FilterDateOptions.Due_Time
          );
          dueDateFilter.filterAction = FilterActionTypes.Add;
        }else {

          dueDateFilter.filterAction = FilterActionTypes.Update;
          dueDateFilter.filterValue = closedDate;
        }
        filtersToUpdate.push(dueDateFilter);
      }

      this.incidentItem.headlineStatus = status.comment;
      this.incidentItem.headlineStatusUpdated = new Date().toJSON();
      const headlineStatusFilter = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Headline_Status);
      headlineStatusFilter.filterValue = status.comment;
      headlineStatusFilter.filterAction = FilterActionTypes.Update;
      filtersToUpdate.push(headlineStatusFilter);


      this.onFiltersUpdated(filtersToUpdate);
    } else {
      const matching = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Closed);
      if (matching) {
        matching.filterValue = '';
        matching.filterAction = FilterActionTypes.Update;
        filtersToUpdate.push(matching);
        this.incidentItem.closed = '';
      }
      this.onFiltersUpdated(filtersToUpdate);
    }
  }

  public onTitleUpdate(newTitle: string) {
    this.incidentItem.title = newTitle;

    const titleFilter = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Title);
    if (titleFilter) {
      titleFilter.filterValue = newTitle;
      titleFilter.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([titleFilter]);
  }

  public onHeadlineStatusUpdated(item: IncidentListItemViewModel) {
    this.incidentItem.headlineStatus = item.headlineStatus;
    this.incidentItem.headlineStatusUpdated = new Date().toJSON();
    const filter = this.incidentItem.filters.find((f) => f.filterType === FilterTypes.Headline_Status);
    if (filter) {
      filter.filterValue = item.headlineStatus;
      filter.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([filter]);
  }

  public updateDescription(description: string) {
    this.incidentItem.description = description;

    const matching = this.incidentItem?.filters?.find((f) => f.filterType === FilterTypes.Description);
    if (matching) {
      matching.filterValue = description;
      matching.filterText = description;
      matching.filterAction = FilterActionTypes.Update;
    }

    this.saveFiltersChanges([matching]);
  }

  public onFiltersUpdated(filters: FilterViewModel[]): void {
    this.saveFiltersChanges(filters);
  }

  public onDatesChanged(date: NgRangeDatesOutput): void {
    const filtersToUpdate: FilterViewModel[] = [];
    if (date.startDate && this.incidentItem.startTime !== date.startDate) {
      this.incidentItem.startTime = date.startDate as string;
      const dateFilter = this.incidentItem.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Time
      );
      dateFilter.filterValue = this.incidentItem.startTime;
      dateFilter.filterAction = FilterActionTypes.Add;
      filtersToUpdate.push(dateFilter);
    }

    if (date.endDate && this.incidentItem.endTime !== date.endDate) {
      this.incidentItem.endTime = date.endDate as string;
      const filter = this.incidentItem.filters[0];

      let dateFilter = this.incidentItem.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Time
      );

      if (!dateFilter) {
        dateFilter = FilterUtilities.GenerateDateFilter(FilterDateOptions.Due_Time, FilterSelectorTypes.Date, undefined);
        dateFilter.displayForGlobalObjectType = filter.displayForGlobalObjectType;
        this.incidentItem.filters.push(dateFilter);
      }

      dateFilter.filterValue = date.endDate;
      dateFilter.filterAction = FilterActionTypes.Add;
      filtersToUpdate.push(dateFilter);

      if (!this.incidentItem.startTime) {
        const dateFilter = this.incidentItem.filters.find(
          (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Time
        );
        this.incidentItem.startTime = date.endDate as string;
        dateFilter.filterValue = this.incidentItem.startTime;
        dateFilter.filterAction = FilterActionTypes.Add;
        filtersToUpdate.push(dateFilter);
      }
    }

    if(filtersToUpdate.length > 0){
      this.saveFiltersChanges(filtersToUpdate);
    }
  }

  public updateReportedBy(reporter: string): void {
    const filter = FilterUtilities.GenerateFilter(
      FilterTypes.Reported_By,
      reporter,
      reporter,
      undefined,
      true,
      ObjectTypes.IncidentItem
    );
    filter.filterAction = FilterActionTypes.Update;

    this.saveFiltersChanges([filter]);
  }

  public navigateToDetaislPage() {
    const route = [this.account.url, RouteHelper.getRoute(this.account.accountUID, this.objectType), this.incidentItem.id].join('');
    window.open(route, '_blank');
  }

  private checkUserWritePermission(): void {
    this.canEdit = this.hasWritePermissionPipe.transform(this.incidentItem, ModuleTypes.Incidents, FilterTypes.Incident);
  }

  private saveFiltersChanges(filters: FilterViewModel[]) {
    const onlyFiltersWithActions = filters.filter((a) => a.filterAction !== FilterActionTypes.None);

    this.accountHubService
      .updateObject(this.incidentItem.id, this.incidentItem.accountId, this.objectType, onlyFiltersWithActions)
      .subscribe((res) => {
        const changes = res.changes as unknown as FilterViewModel[];
        this.incidentItem.filters = FilterUtilities.ApplyFilterActions(this.incidentItem.filters, onlyFiltersWithActions);
        this.incidentItem.filters = FilterUtilities.ApplyFilterActions(this.incidentItem.filters, changes);
        this.objectUpdated.emit(this.incidentItem);
      });
  }

  private setClosedFilter(filters: FilterViewModel[], filterValue: string) {
    const matching = filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Closed);
    if (matching) {
      matching.filterValue = filterValue;
      matching.filterAction = FilterActionTypes.Update;
    } else {
      const closedFilter = FilterUtilities.GenerateFilter(FilterTypes.Date, filterValue, filterValue, FilterDateOptions.Closed);
      closedFilter.filterAction = FilterActionTypes.Update;
      filters.push(closedFilter);
    }

    if (!filterValue || !filterValue.length) {
      this.incidentItem.closed = '';
    }

    return filters;
  }
}

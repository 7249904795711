import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { AddExternalUserModal } from 'src/app/modules/shared/components/modals/add-external-user-modal/add-external-user-modal.component';
import { BsModalConfig } from 'src/app/modules/shared/config/modal.config';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ReportSendIntervalTypes } from 'src/app/modules/shared/enums/reports/reportSendIntervalTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { ReportAttachType } from '../../../enums/reportAttachType.enum';
import { ReportStatus } from '../../../enums/reportStatus.enum';
import { ReportUtilityService } from '../../../services/report-utility-service';
import { ReportingService } from '../../../services/reporting.service';
import { ReportItemViewModel } from '../../../viewModels/reportItemViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { Account } from 'src/app/modules/shared/models/account';

enum ReportScheduleType {
  Regular,
  Custom,
}

@Component({
  selector: 'app-share-report-settings-modal',
  templateUrl: './share-report-settings-modal.component.html',
  styleUrls: ['./share-report-settings-modal.component.scss'],
})
export class ShareReportSettingsModalComponent implements OnInit, OnDestroy {
  public reportItem: ReportItemViewModel;

  public form: UntypedFormGroup;

  public attachmentTypeFC: UntypedFormControl;
  public readonly reportAttachTypes = ReportAttachType;

  public scheduleTypeFC: UntypedFormControl;
  public readonly reportScheduleTypes = ReportScheduleType;

  public filtersFC: UntypedFormControl;
  public readonly filterTypes = FilterTypes;
  private allowedFilters: FilterViewModel[] = [];

  public sendStartDateFC: UntypedFormControl;
  public sendEndDateFC: UntypedFormControl;
  public minStartDate: Date = new Date();
  public minEndDate: Date = new Date();
  public customSendDateToAdd: string;
  public reportItemShareDatesFC: UntypedFormControl;

  public sendIntervalTypeFC: UntypedFormControl;
  public reportSendIntervalTypes = [
    { value: ReportSendIntervalTypes.Hourly, label: this.translateService.instant(T.reports.hourly_at_chosen_time) },
    { value: ReportSendIntervalTypes.Daily, label: this.translateService.instant(T.reports.daily_at_chosen_time) },
    { value: ReportSendIntervalTypes.Weekly, label: this.translateService.instant(T.reports.weekly_at_chosen_time) },
    { value: ReportSendIntervalTypes.Monthly, label: this.translateService.instant(T.reports.monthly_at_chosen_time) },
  ];
  public currentSendIntervalTypeObject = { value: ReportSendIntervalTypes.Empty, label: '' };
  public lastShareDate: string;

  public isScheduled: boolean = false;
  public isLoading = false;

  public onReportShare: Subject<ReportItemViewModel> = new Subject<ReportItemViewModel>();
  public readonly T = T;

  private currentEmployee: Employee;
  private subscriptions = new Subscription();
  private account: Account;

  constructor(
    private readonly modalConfig: BsModalConfig,
    private readonly confirmationService: ConfirmationService,
    private readonly bsModalRef: BsModalRef<ShareReportSettingsModalComponent>,
    private readonly bsModalService: BsModalService,
    private readonly elemRef: ElementRef,
    private readonly authenticationService: AuthenticationService,
    private readonly fb: UntypedFormBuilder,
    private readonly timeZoneService: TimeZoneService,
    private readonly reportingService: ReportingService,
    private readonly reporUtilityService: ReportUtilityService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();
    this.initForm();
    this.initModalFromReportProps();
    this.initReportDates();
    this.initAllowedFilters();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initForm() {
    this.form = this.fb.group({
      title: [
        '',
        [Validators.minLength(3), Validators.maxLength(80), Validators.required, Validators.pattern(/([^\s].{1,}[^\s])/)],
      ],
      description: ['', Validators.maxLength(250)],
      filters: [[], Validators.required],
      attachmentType: [ReportAttachType.PDF, Validators.required],
      scheduleType: [ReportScheduleType.Regular, Validators.required],
      sendIntervalType: [null, Validators.required],
      sendStartDate: ['', Validators.required],
      sendEndDate: ['', Validators.required],
      reportItemShareDates: [{ value: [], disabled: true }, Validators.required],
    });

    this.attachmentTypeFC = this.form.get('attachmentType') as UntypedFormControl;
    this.scheduleTypeFC = this.form.get('scheduleType') as UntypedFormControl;
    this.sendIntervalTypeFC = this.form.get('sendIntervalType') as UntypedFormControl;
    this.sendStartDateFC = this.form.get('sendStartDate') as UntypedFormControl;
    this.sendEndDateFC = this.form.get('sendEndDate') as UntypedFormControl;
    this.filtersFC = this.form.get('filters') as UntypedFormControl;
    this.reportItemShareDatesFC = this.form.get('reportItemShareDates') as UntypedFormControl;

    if (this.isScheduled) {
      this.subscriptions.add(
        this.sendEndDateFC.valueChanges.subscribe((value: string) => {
          this.lastShareDate = this.reporUtilityService.getLastReportDate(value).toISOString();
        })
      );

      this.subscriptions.add(
        this.sendIntervalTypeFC.valueChanges.subscribe((value: ReportSendIntervalTypes) => {
          const foundIntervalType = this.reportSendIntervalTypes.find((type) => type.value == value);
          if (foundIntervalType) {
            this.currentSendIntervalTypeObject = foundIntervalType;
          } else {
            this.currentSendIntervalTypeObject = { value: ReportSendIntervalTypes.Empty, label: '' };
          }
        })
      );

      this.subscriptions.add(
        this.scheduleTypeFC.valueChanges.subscribe((value: ReportScheduleType) => {
          if (value === ReportScheduleType.Regular) {
            this.sendIntervalTypeFC.enable();
            this.sendEndDateFC.enable();
            this.sendStartDateFC.enable();
            this.reportItemShareDatesFC.disable();
          } else if (value === ReportScheduleType.Custom) {
            this.sendIntervalTypeFC.disable();
            this.sendEndDateFC.disable();
            this.sendStartDateFC.disable();
            this.reportItemShareDatesFC.enable();
          }
        })
      );
    } else {
      this.form.removeControl('scheduleType');
      this.form.removeControl('sendIntervalType');
      this.form.removeControl('sendStartDate');
      this.form.removeControl('sendEndDate');
      this.form.removeControl('reportItemShareDates');
    }
  }

  private initModalFromReportProps() {
    if (this.reportItem.title) {
      this.form.get('title').setValue(this.reportItem.title);
    }

    if (this.reportItem.description) {
      this.form.get('description').setValue(this.reportItem.description);
    }

    if (this.reportItem.sendIntervalType) {
      const foundIntervalType = this.reportSendIntervalTypes.find((type) => type.value == this.reportItem.sendIntervalType);
      if (foundIntervalType) {
        this.sendIntervalTypeFC.setValue(foundIntervalType.value);
      }
    }

    if (this.reportItem.sendIntervalType === ReportSendIntervalTypes.Custom_Dates) {
      this.scheduleTypeFC.setValue(ReportScheduleType.Custom);
    } else if (this.isScheduled) {
      this.scheduleTypeFC.setValue(ReportScheduleType.Regular);
    }

    if (this.reportItem.attachmentType) {
      this.attachmentTypeFC.setValue(this.reportItem.attachmentType);
    }

    if (this.reportItem.filters) {
      this.filtersFC.setValue(this.reportItem.filters);
    }
  }

  getSharingSettingsSummary() {
    // TODO: Add i18n support for interval types
    const howOften = this.isEmptyOrCustomSchedule()
      ? undefined
      : ReportSendIntervalTypes[this.sendIntervalTypeFC.value].toString().replace('_', ' ').toLowerCase();

    const howMany = this.filtersFC.value?.length;
    const attachmentType = this.attachmentTypeFC.value === ReportAttachType.PDF ? 'PDF' : 'XLSX';

    let summary: string;

    if (howOften && this.scheduleTypeFC.value === ReportScheduleType.Regular) {
      if (howMany === 1) {
        summary = this.translateService.instant(T.reports.attachmentType_report_will_be_sent_howOften.one, {
          attachmentType,
          howOften,
        });
      } else {
        summary = this.translateService.instant(T.reports.attachmentType_report_will_be_sent_howOften.many, {
          attachmentType,
          howOften,
          howMany,
        });
      }
    } else {
      if (howMany === 1) {
        summary = this.translateService.instant(T.reports.attachmentType_report_will_be_sent_to.one, { attachmentType });
      } else {
        summary = this.translateService.instant(T.reports.attachmentType_report_will_be_sent_to.many, {
          attachmentType,
          howMany,
        });
      }
    }

    return summary;
  }

  private initReportDates() {
    const today = this.timeZoneService.getCurrentMomentInLocalisedTimezone();

    if (!this.reportItem.sendStartDate) {
      this.sendStartDateFC.setValue(today.toISOString());
      this.minEndDate = today.toDate();
    } else {
      this.sendStartDateFC.setValue(this.reportItem.sendStartDate);
      this.minEndDate = new Date(this.reportItem.sendStartDate);
    }

    if (this.reportItem.sendEndDate) {
      this.sendEndDateFC.setValue(this.reportItem.sendEndDate);
    } else {
      this.sendEndDateFC.setValue(today.toISOString());
    }

    if (this.reportItem.reportItemShareDates?.length) {
      this.reportItemShareDatesFC.setValue(this.reportItem.reportItemShareDates.map((reportDate) => reportDate.sentOn));
    }
  }

  handleShareWithFilterChange(filters: FilterViewModel[]) {
    this.filtersFC.setValue(filters);
  }

  onSendStartDateChanged(dateAsIsoString: string) {
    this.minEndDate = new Date(dateAsIsoString);
    this.sendStartDateFC.setValue(dateAsIsoString);

    if (this.sendEndDateFC.value && moment(dateAsIsoString).isAfter(moment(this.sendEndDateFC.value), 'day')) {
      this.sendStartDateFC.setErrors({ startDateIsAfterEndDate: true });
    }
  }

  onSendEndDateChanged(dateAsIsoString: string) {
    this.sendEndDateFC.setValue(dateAsIsoString);

    if (this.sendEndDateFC.value && moment(this.sendStartDateFC.value).isAfter(moment(dateAsIsoString), 'day')) {
      this.sendStartDateFC.setErrors({ startDateIsAfterEndDate: true });
    } else {
      this.sendStartDateFC.updateValueAndValidity();
    }
  }

  onTimeChanged(dateAsIsoString: string) {
    this.minEndDate = new Date(dateAsIsoString);
    this.sendStartDateFC.setValue(dateAsIsoString);

    if (this.sendEndDateFC.value && moment(dateAsIsoString).isAfter(moment(this.sendEndDateFC.value), 'day')) {
      this.sendStartDateFC.setErrors({ startDateIsAfterEndDate: true });
    }
  }

  onCustomSendDateChanged(dateAsIsoString: string) {
    this.customSendDateToAdd = dateAsIsoString;
  }

  onReportSendIntervalTypeSelect(typeOption: { value: ReportSendIntervalTypes; label: string }) {
    this.sendIntervalTypeFC.setValue(typeOption.value);
  }

  onReportSendIntervalTypeClear() {
    this.sendIntervalTypeFC.setValue(null);
  }

  onXLSXChecked() {
    this.attachmentTypeFC.setValue(ReportAttachType.CSV);
  }

  onPDFChecked() {
    this.attachmentTypeFC.setValue(ReportAttachType.PDF);
  }

  onRegularScheduleChecked() {
    this.scheduleTypeFC.setValue(ReportScheduleType.Regular);
  }

  onCustomScheduleChecked() {
    this.scheduleTypeFC.setValue(ReportScheduleType.Custom);
  }

  clearCustomDateInputs() {
    this.customSendDateToAdd = undefined;
  }

  addCustomDateToSchedule() {
    if (!this.customSendDateToAdd) {
      return;
    }

    const newDates = [...this.reportItemShareDatesFC.value, this.customSendDateToAdd].sort();
    newDates.sort((a, b) => a - b);
    this.reportItemShareDatesFC.setValue(newDates);
    this.customSendDateToAdd = undefined;
  }

  removeCustomDate(index: number) {
    const newDates = [...this.reportItemShareDatesFC.value];
    newDates.splice(index, 1);
    this.reportItemShareDatesFC.setValue(newDates);
  }

  removeAllCustomDates() {
    this.reportItemShareDatesFC.setValue([]);
  }

  private initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
  }

  inludeCurrentEmployeeInShared() {
    const empFilter = this.allowedFilters.find(
      (s) => s.filterType === FilterTypes.Employee && s.filterValue.toString() === this.currentEmployee.id.toString()
    );

    if (
      empFilter !== undefined &&
      this.filtersFC.value.findIndex((f: FilterViewModel) => f.filterValue == empFilter.filterValue) < 0
    ) {
      this.filtersFC.setValue([...this.filtersFC.value, empFilter]);
    }
  }

  addNewExternalUser() {
    const modalRef = this.bsModalService.show(AddExternalUserModal, this.modalConfig);

    this.subscriptions.add(
      modalRef.content.userAdded.subscribe((userModel) => {
        // HACK: Construct a filter based on the user model.
        // The real filter is not available yet because the filters service
        // updates are async and there is no mechanism to track them.
        const externalUserFilter = FilterUtilities.GenerateFilter(
          FilterTypes.External_User,
          userModel.id,
          `${userModel.firstName} ${userModel.surname}`
        );

        this.filtersFC.setValue([...this.filtersFC.value, externalUserFilter]);
      })
    );
  }

  private isEmptyOrCustomSchedule() {
    return (
      !this.sendIntervalTypeFC.value ||
      this.sendIntervalTypeFC.value === ReportSendIntervalTypes.Empty ||
      this.sendIntervalTypeFC.value === ReportSendIntervalTypes.Custom_Dates
    );
  }

  onApply() {
    if (!this.form.valid) {
      return;
    }

    // Prepare report item for sharing
    const employeeIDs = this.filtersFC.value.map((fvm: FilterViewModel) => fvm.filterValue as string);
    this.reportItem.sentToCSV = employeeIDs.join(',');
    this.reportItem.filters = this.filtersFC.value;

    this.reportItem.title = this.form.get('title').value;
    this.reportItem.description = this.form.get('description').value;

    this.reportItem.originalReportItem = this.reportItem.id;
    this.reportItem.reportStatus = ReportStatus.Shared;

    this.reportItem.attachmentType = this.attachmentTypeFC.value;

    if (this.isScheduled && this.scheduleTypeFC.value === ReportScheduleType.Custom) {
      this.reportItem.sendIntervalType = ReportSendIntervalTypes.Custom_Dates;
    } else if (this.isScheduled) {
      this.reportItem.sendIntervalType = this.sendIntervalTypeFC.value;
    } else {
      this.reportItem.sendIntervalType = ReportSendIntervalTypes.Right_Now;
    }

    this.reportItem.sendStartDate = !this.isEmptyOrCustomSchedule() ? this.sendStartDateFC.value : null;
    this.reportItem.sendEndDate = !this.isEmptyOrCustomSchedule() ? this.sendEndDateFC.value : null;

    if (this.scheduleTypeFC.value === ReportScheduleType.Custom) {
      this.reportItem.reportItemShareDates = this.reportItemShareDatesFC.value.map((date: string) => {
        return { sentOn: date };
      });
    } else {
      this.reportItem.reportItemShareDates = null;
    }

    // Initiate request
    this.isLoading = true;

    this.reportingService.share(this.reportItem).subscribe({
      next: (newReport) => {
        this.isLoading = false;

        this.onReportShare.next(newReport);

        if (newReport.id !== this.reportItem.id) {
          void this.router.navigate([`${this.account.accountUID}/reports/preview/${newReport.id}/${newReport.reportItemSections[0].id}`]);
        } else {
          this.reportItem = newReport;
        }

        this.onClose();
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  onClose() {
    this.bsModalRef.hide();
  }

  confirmClose() {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.reports.save_changes_before_closing),
      () => {
        this.onClose();
      },
      () => {
        //handle cancel
      },
      this.translateService.instant(T.common.confirm),
      false,
      this.translateService.instant(T.common.stay),
      this.translateService.instant(T.common.leave),
      'danger'
    );
  }

  get isSingleSectionReport() {
    return this.reportItem.reportItemSections && this.reportItem.reportItemSections.length === 1;
  }
}

<app-modal *ngIf="!loading">
  <app-modal-header [title]="T.common.item_details | translate: { item: localisedRiskAction }" (closed)="closeModal()"></app-modal-header>
  <app-modal-body>
    <app-details-header
    [item]="currentDetailsItem"
    [canEdit]="canEditRisk"
    [localisedItem]="localisedItem"
    [showBackNavigation]="false"
    [listUrl]="':accountId/risk/risks-issues' | url"
    [filterType]="filterTypes.Risk"
    [useBrowserBackNavigation]="true"
    [showRefCode]="true"
    [showMoreButton]="false"
    [showId]="true"
    (titleUpdated)="updateTitle($event)"
    (descriptionUpdated)="updateDescription($event)"
    [useSimpleTitleEdit]="true"
    [showDescription]="true"
  >
    <ng-container header-object-title-desktop>
      <div class="me-2">
        <app-details-dropdown
        [isStatus]="true"
        [displayLabel]="false"
        [label]="T.common.status.one | translate"
        [canEdit]="canEditRisk"
        [filterType]="filterTypes.Risk_Action_Status"
        (onFiltersUpdated)="updateFilters($event)"
        [statusBars]="false"
        [appliedFilters]="currentDetailsItem.filters"
        [arrowPosition]="'right'"
        ></app-details-dropdown>
      </div>
      <ng-container *ngIf="!isComplete && currentDetailsItem.dueDate">
        |
        <app-due-indication-badge class="ms-2 me-2" [dueDate]="currentDetailsItem.dueDate" [variant]="'slim'" [isComplete]="isComplete"></app-due-indication-badge>
      </ng-container>
      |
      <div class="ms-2">
        <app-avatar-filter-selector
        [filterType]="filterTypes.Owner"
        [filters]="currentDetailsItem.filters"
        [canEdit]="canEditRisk"
        [showEmptyAddBtn]="true"
        (filtersUpdated)="updateFilters($event)"
        ></app-avatar-filter-selector>
      </div>
    </ng-container>
  </app-details-header>
  <div class="details-container">
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ objectTypes.Risk | localiseObjectType: true }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Risk"
          [singleSelect]="false"
          [canEdit]="canEditRisk"
          [showEmptyFilters]="false"
          [accordionLozenge]="true"
          (onFiltersUpdated)="updateFilters($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ T.common.type.one | translate }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Risk_Action_Type"
          [canEdit]="canEditRisk"
          [accordionLozenge]="true"
          [singleSelect]="true"
          (onFiltersUpdated)="updateFilters($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.due_date.one | translate }}</div>
      <div class="col-7 details-value">
        <app-details-datepicker
          [date]="currentDetailsItem.dueDate"
          [canEdit]="canEditRisk"
          [accordionLozenge]="true"
          [label]="T.defaultLocalizations.due_date.one | translate"
          (dateChanged)="onDueDateChange($event)"
        ></app-details-datepicker>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ T.defaultLocalizations.owner.one | translate }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Owner"
          [canEdit]="canEditRisk"
          [accordionLozenge]="true"
          (onFiltersUpdated)="updateFilters($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ objectTypes.User_Group | localiseObjectType: true }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [accordionLozenge]="true"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.User_Group"
          [canEdit]="canEditRisk"
          (onFiltersUpdated)="updateFilters($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ T.defaultLocalizations.job_category.one | translate }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Job_Category"
          [canEdit]="canEditRisk"
          [accordionLozenge]="true"
          (onFiltersUpdated)="updateFilters($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
  </div>
  <div class="details-accordion">
    <app-accordion-details [collapsing]="false" (onItemIndexClicked)="onItemIndexClicked($event)">
      <app-accordion-item-details [title]="'History'" [expanded]="false">
        <ng-template accordionContent>
          <div *ngIf="historyExpanded" class="history-feed-wrapper">
            <app-history-feed-new
              [showInput]="true"
              [canEdit]="userHasWriteAccess"
              [objectId]="currentDetailsItem.id"
              [objectType]="actionItemObjectType"
              [showHeader]="false"
              [singleItemHistory]="true"
              (historyEntriesCount)="onHistoryEntriesUpdate($event)"
            >
            </app-history-feed-new>
          </div>
        </ng-template>
      </app-accordion-item-details>
    </app-accordion-details>
  </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-icon-square
    [icon]="'more_horiz'"
    ngDropdown
    [templateRef]="moreButtonTemplate"
    ></app-button-icon-square>
    <app-button-rectangle [size]="'large'"  [text]="T.common.close | translate" (clicked)="closeModal()"></app-button-rectangle>
  </app-modal-footer>
</app-modal>

<ng-template #moreButtonTemplate>
  <div class="dropdown-options-container">
    <div class="dropdown-option" *ngIf="canEditRisk" (click)="openAddUpdateModal()">{{ addCommentText }}</div>
    <div class="dropdown-option" (click)="openShareModal()">
      {{ T.common.share_item | translate: { item: localisedItem } }}
    </div>
    <div class="dropdown-option" *ngIf="!currentDetailsItem.isPinned" (click)="pinItem()">{{ pinToMyTrackText }}</div>
    <div class="dropdown-option" *ngIf="currentDetailsItem.isPinned" (click)="unpinItem()">
      {{ unpinFromMyTrackText }}
    </div>
    <div
      *ngIf="(currentDetailsItem | canDelete: moduleTypes.Planning:filterTypes.Risk) && !isArchived"
      (click)="openArchiveModal(true)"
      class="dropdown-option"
    >
      {{ archiveText }}
    </div>
    <div
      *ngIf="(currentDetailsItem | canDelete: moduleTypes.Planning:filterTypes.Risk) && isArchived"
      (click)="openArchiveModal(false)"
      class="dropdown-option"
    >
      {{ unarchiveText }}
    </div>
    <div
      *ngIf="{ id: risk.id } | canDelete: moduleTypes.Planning:filterTypes.Risk"
      (click)="deleteAction()"
      class="dropdown-option"
    >
      {{ deleteText }}
    </div>
  </div>
</ng-template>

import { RiskTypes } from '../../risk/enums/riskTypes';
import { ObjectTypes } from '../enums/objectTypes';

export class RouteHelper {
  // create static dictionary of object type and routes
  private static routes: { [key: number]: string } = {
    [ObjectTypes.Project]: `/planning/list/projects/`,
    [ObjectTypes.Task]: `/planning/list/tasks/`,
    [ObjectTypes.Risk]: `/risk/risks-issues/`,
    [ObjectTypes.IncidentItem]: `/control/list/`,
    [ObjectTypes.Log]: `/control/logs/`,
    [ObjectTypes.Job]: `/control/jobs/`,
    [ObjectTypes.Runsheet]: `/runsheets/list/`,
    [ObjectTypes.Runsheet_Item]: `/runsheets/list/`,
    [ObjectTypes.Indicator]: `/sustainability/indicators/`,
  };

  /**
   *  Get Route to specific Details page based on the Object Type and Risk Type (if it is applicable)
   * @param objectType  - Object Type
   * @param riskType  - Risk Type (if it is applicable)
   * @returns  - Route to specific Details page
   */
  public static getRoute(accountUID: string, objectType: ObjectTypes, riskType: RiskTypes = undefined): string {
    let generalRoute = `/${accountUID}${this.routes[objectType]}`;
    if (riskType) {
      generalRoute += this.getRiskSubTypeRoute(riskType);
    }

    return generalRoute;
  }

  private static getRiskSubTypeRoute(riskType: RiskTypes) {
    switch (riskType) {
      case RiskTypes.Risk:
        return 'risk/';
      case RiskTypes.Issue:
        return 'issue/';
      case RiskTypes.Opportunity:
        return 'opportunity/';
    }
  }
}

<div class="bs-datepicker-wrapper" *ngIf="!range">
  <div class="cool-input cool-input-with-icon"
       [class.disable-margin]="disableMargin"
       [class.use-full-border]="showOutlined"
       [class.mobile]="isMobile">
    <input
      #desktopInput
      type="text"
      class="{{alignIconLeftStyle ? alignIconLeftStyle : 'form-control'}}"
      [class.required]="required"
      [required]="required"
      autocomplete="off"
      ngdatepicker
      [date]="value"
      [helperDate]="helperValue"
      [isEndDate]="isEndDateField"
      [minDate]="minDateValue"
      [maxDate]="maxDateValue"
      [usePredefinedAvailableDates]="usePredefinedAvailableDates"
      [predefinedAvailableDates]="predefinedAvailableDates"
      [value]="inputValue"
      [noTimeZoneLocalisation]="isTimeZoneFormattedInAccount"
      (dateChanged)="onDateChanged($event)"
      ngDateMask
      [format]="format"
      [separator]="'/'"
      [disabled]="disabled"
      inputmode="none"
      [class.cool-custom-input-invalid]="displayError"
      [class.input-label-shown]="inputValue && !displayError"
      [minMaxDateStrategy]="minMaxDateStrategy"
    />
    <label class="{{alignIconLeftStyle ? 'align-text-left' : ''}}">{{ placeholder }}</label>
    <span class="{{alignIconLeftStyle ? 'align-icon-left cool-input-icon' : 'cool-input-icon'}}">
      <i class="material-symbols-outlined"> calendar_today</i>
    </span>
    <span class="validation-message" *ngIf="displayError">{{ errorMsg }}</span>
  </div>
</div>
<div class="bs-datepicker-wrapper" *ngIf="range">
  <div class="cool-input cool-input-with-icon" [class.disable-margin]="disableMargin" [class.mobile]="isMobile">
    <input
      #desktopInput
      type="text"
      class="form-control"
      [class.required]="required"
      [required]="required"
      autocomplete="off"
      ngRangeDatepicker
      (startDateChanged)="onDateChanged($event)"
      (endDateChanged)="onEndDateChanged($event)"
      [startDate]="value"
      [endDate]="endDateValue"
      [minDate]="minDateValue"
      [maxDate]="maxDateValue"
      [showFooter]="showFooter"
      [showHeader]="showHeader"
      [value]="inputValue"
      ngDateMask
      [format]="format"
      [separator]="'/'"
      [disabled]="disabled"
      inputmode="none"
      [class.cool-custom-input-invalid]="displayError"
      [class.input-label-shown]="inputValue && !displayError"
    />
    <label>{{ placeholder }}</label>
    <span class="cool-input-icon"><i class="material-symbols-outlined">calendar_today</i></span>
    <span class="validation-message" *ngIf="displayError">{{ errorMsg }}</span>
  </div>
</div>

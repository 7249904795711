import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EmployeeSettingsService } from '../../services/employee-settings.service';
import { NavigationService } from '../../services/navigation.service';
import { EmployeeUtil } from '../../utilities/employee.utilities';
import { EmployeeRoleTypes } from '../../enums/employees/EmployeeRoleTypes';

@Injectable({
  providedIn: 'root',
})
export class MyTrackGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    protected readonly navigationService: NavigationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const haveRedirectUrl = this.authGuardService.GetRedirectCookie();
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe((res) => {
            const result = this.checkPermissions(haveRedirectUrl);

            observer.next(result);
          });
        });
      } else {
        const result = this.checkPermissions(haveRedirectUrl);
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  checkPermissions(url: string): boolean {
    let result = false;
    const account = this.authService.getCurrentAccount();
    const employee = this.authService.getCurrentEmployee();
    const hasPlanningRole = EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Planning_User) || EmployeeUtil.IsAdmin(employee);
    const hasRiskUserRole = EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Risks_User) || EmployeeUtil.IsAdmin(employee);

    if (!account.isActive) {
      void this.router.navigateByUrl(`${account.accountUID}/unauthorized`);
    }

    if  (account.isHubAccount) {
      const isReports = url && url.indexOf('reports') !== -1;
      const hasPreviousUrl = this.navigationService.hasPreviousUrl;
      if(url) {
        // After refreshing the page, it will always returns you to the refreshing url
        void this.router.navigateByUrl(url);
      }
      if (!url && !isReports && !hasPreviousUrl) {
        //after login redirect you to home page
        void this.router.navigateByUrl(`${account.accountUID}/hub`);
      }
      return true;
    }

    if (hasPlanningRole || hasRiskUserRole) {
      result = true;
    }

    if (!result && (account.useIMSOnly || account.useIMS) && !url) {
      void this.router.navigateByUrl(`${account.accountUID}/control/list`);
    } else if (!result && account.useRunSheets && !url) {
      void this.router.navigateByUrl(`${account.accountUID}/runsheets/list`);
    } else if (!result && account.useSustainability && !url) {
      void this.router.navigateByUrl(`${account.accountUID}/sustainability/indicators`);
    }

    return result;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { Observable, of, Observer } from 'rxjs';
import { EmployeeSettingsService } from '../../services/employee-settings.service';

@Injectable()
export class PEAPsGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe((res) => {
            const result = this.checkPermissions();

            observer.next(result);
          });
        });
      } else {
        const result = this.checkPermissions();
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  checkPermissions(): boolean {
    let result = false;
    const account = this.authService.getCurrentAccount();

    if (!account.isActive) {
      void this.router.navigateByUrl(`${account.accountUID}/unauthorized`);
    }

    if (account.usePeaps) {
      result = true;
    }

    if (!result) {
      void this.router.navigateByUrl(`${account.accountUID}/mytrack/planning`);
    }

    return result;
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(private authenitcitonservice: AuthenticationService) {}

  public link: string = `/0/mytrack/planning`;

  ngOnInit() {
    const currentAccount = this.authenitcitonservice.getCurrentAccount();

    if (currentAccount && currentAccount.useIMSOnly) this.link = `/${currentAccount.accountUID}/control/list`;
  }
}

<app-modal>
  <app-loading *ngIf="loading"></app-loading>
  <ng-container *ngIf="!loading">
    <app-modal-header
      [title]="T.common.add_item | translate: { item: localisedLog }"
      [showDelete]="false"
      (closed)="closeModal()"
    >
    </app-modal-header>
    <app-modal-body>
      <div [formGroup]="logForm">
        <section class="row col-row-space custom-gutters section-padding">
          <div class="col-12">
            <app-text-field
              [formGroup]="logForm"
              formControlName="title"
              [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
              [useReactiveMessages]="true"
              [required]="true"
            ></app-text-field>
          </div>
          <div class="col-12">
            <app-filter-dropdown-new
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Incident_Category"
              [appliedFilters]="filters"
              [typeahead]="true"
              [required]="true"
              [singleSelect]="true"
              [label]="(T.control.add_incident_category.many | translate) + '*'"
              (onFilterUpdate)="handleFilterChange($event)"
              (isValid)="isCategoryDropdownValid($event)"
            >
            </app-filter-dropdown-new>
          </div>
          <div class="col-12">
            <app-text-field
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="incidentLog.headlineStatus"
              name="headlineStatus"
              [placeholder]="T.defaultLocalizations.headline_status.one | translate"
              [maxLength]="255"
              [minLength]="3"
              [required]="false">
            </app-text-field>
          </div>
          <div class="col-12">
            <app-details-editable-field
              [input]="incidentLog.description ? incidentLog.description : ''"
              [maxLength]="2000"
              [minLength]="0"
              [type]="editableFieldTypes.RichText"
              [placeholder]="T.common.add_description | translate"
              [canEdit]="true"
              (onUpdate)="updateDescription($event)">
            </app-details-editable-field>
          </div>

          <div *ngIf="showPrivacyStatus" class="checkbox-wrapper col-12">
            <app-checkbox
                [isChecked]="privacyStatus.key !== privacyStatusesEnum.Open"
                (checked)="onMakeConfidentialChecked($event)">
              </app-checkbox>
            <span> {{ T.common.make_confidential | translate }} </span>

          </div>

          <div *ngIf="showPrivacyStatus && showConfidentialStatuses" class="col-12">
            <app-simple-dropdown
              #searchDropdown
              [objects]="filteredStatuses"
              [bindProperty]="'value'"
              [label]="T.defaultLocalizations.privacy_status.one | translate"
              [currentlySelectedObject]="privacyStatus"
              (objectSelected)="onPrivacyStatusSelected($event)">
            </app-simple-dropdown>
          </div>

          <div class="col-12 mb-0">
            <app-optional-date-time
              [firstDateLabel]="T.calendar.start | translate"
              [secondDateLabel]="T.calendar.end | translate"
              [firstDateString]="incidentLog.startTime"
              [secondDateString]="incidentLog.endTime"
              [showPickers]="showPickers"
              [editMode]="true"
              (onFirstDateChanged)="onStartDateChanged($event)"
              (onSecondDateChanged)="onEndDateChanged($event)"
              (onShowPickersChanged)="onShowPickersChanged($event)"
            ></app-optional-date-time>
          </div>

          <div *ngIf="isCalledFromPIR && copiedEventFilter" class="checkbox-wrapper col-12">
            <app-checkbox
              [isChecked]="copyEventFromPIR"
              (checked)="copyEventFromPIR = $event"
            >
            </app-checkbox>
            <span>{{ T.common.copy_event_from_incoming_report | translate }}</span>
          </div>
        </section>
        <app-accordion-details [collapsing]="false" [emphasizedHeaders]="true">
          <app-accordion-item-details [title]="T.common.assign | translate" [expanded]="false">
            <ng-template accordionContent>
              <section class="row col-row-space custom-gutters section-padding">
                <div class="col-12">
                  <app-filter-dropdown-new
                    [filterByModuleObjectType]="false"
                    [filterType]="filterTypes.Owner"
                    [appliedFilters]="filters"
                    [typeahead]="true"
                    [label]="T.common.add_owner.many | translate"
                    (onFilterUpdate)="handleFilterChange($event)"
                  >
                  </app-filter-dropdown-new>
                  <span class="subtext mt-1 mt-sm-0">{{
                    T.common.which_individuals_are_involved_and_should_be_notified | translate
                  }}</span>
                </div>
                <div class="col-12">
                  <app-filter-dropdown-new
                    [filterByModuleObjectType]="false"
                    [filterType]="filterTypes.Department"
                    [appliedFilters]="filters"
                    [typeahead]="true"
                    [label]="T.common.add_item | translate: { item: localisedDepartments }"
                    (onFilterUpdate)="handleFilterChange($event)"
                  >
                  </app-filter-dropdown-new>
                  <span class="subtext mt-1 mt-sm-0">{{
                    T.common.which_items_are_involved | translate: { items: localisedDepartments }
                  }}</span>
                </div>
                <div class="col-12">
                  <app-filter-dropdown-new
                    [filterByModuleObjectType]="false"
                    [filterType]="filterTypes.User_Group"
                    [appliedFilters]="filters"
                    [typeahead]="true"
                    [label]="T.common.add_employee_group.many | translate"
                    (onFilterUpdate)="handleFilterChange($event)"
                  >
                  </app-filter-dropdown-new>
                  <span class="subtext mt-1 mt-sm-0">{{
                    T.common.which_groups_of_localisedEmployees_should_be_notified
                      | translate: { localisedEmployees: objectTypes.Employee | localiseObjectType: true }
                  }}</span>
                </div>
              </section>
            </ng-template>
          </app-accordion-item-details>
          <app-accordion-item-details [title]="T.common.location.one | translate" [expanded]="false">
            <ng-template accordionContent>
              <section class="row col-row-space custom-gutters section-padding">
                <div class="col-12 mb-2">
                  <app-filter-dropdown-new
                    [label]="T.common.add_item | translate: { item: localisedZones }"
                    [filterType]="filterTypes.Zone"
                    [filterByModuleObjectType]="false"
                    [appliedFilters]="filters"
                    [typeahead]="true"
                    (onFilterUpdate)="handleFilterChange($event)"
                  >
                  </app-filter-dropdown-new>
                  <span class="subtext mt-1 mt-sm-0">{{
                    T.common.which_items_are_affected | translate: { items: localisedZones }
                  }}</span>
                </div>
                <div class="col-12 mb-2">
                  <app-filter-dropdown-new
                    [label]="T.common.add_item | translate: { item: localisedAreas }"
                    [filterType]="filterTypes.Area"
                    [filterByModuleObjectType]="false"
                    [appliedFilters]="filters"
                    [typeahead]="true"
                    [usePredefinedFilters]="true"
                    [predefinedFilters]="applicableAreaFilters"
                    [relatedObjectId]="selectedZone?.filterValue"
                    [disabled]="applicableAreaFilters.length === 0"
                    (onFilterUpdate)="handleFilterChange($event)"
                  >
                  </app-filter-dropdown-new>
                  <span class="subtext mt-1 mt-sm-0">{{
                    T.common.which_items_are_affected | translate: { items: localisedAreas }
                  }}</span>
                </div>
                <div class="col-12 mb-2">
                  <app-incident-map
                    [defaultZoom]="15"
                    [lat]="incidentLog.latitude"
                    [lng]="incidentLog.longitude"
                    [selectedZones]="mapZones"
                    [selectedAreas]="mapAreas"
                    [showIncidentZonesUponLoad]="true"
                    [markerColor]="severityColor"
                    [markerType]="markerType"
                    [editMode]="true"
                    (onLocationUpdated)="onIncidentLocationUpdated($event)"
                  >
                  </app-incident-map>
                </div>
              </section>
            </ng-template>
          </app-accordion-item-details>
          <app-accordion-item-details [title]="T.defaultLocalizations.tag.many | translate" [expanded]="false">
            <ng-template accordionContent>
              <section class="row col-row-space custom-gutters section-padding">
                <div class="col-12">
                  <app-add-modal-tag-groups
                    [showTitle]="false"
                    [filters]="filters"
                    [layoutClasses]="'col-12'"
                    [tagGroupObjectType]="objectType"
                    (onFiltersUpdated)="handleFilterChange($event)"
                  ></app-add-modal-tag-groups>
                </div>
              </section>
            </ng-template>
          </app-accordion-item-details>
        </app-accordion-details>

      </div>
    </app-modal-body>
    <app-modal-footer>
      <app-button-rectangle
        [buttonTheme]="'white'"
        [size]="'large'"
        [text]="T.common.cancel | translate"
        (clicked)="closeModal()"
      ></app-button-rectangle>
      <app-button-rectangle
        [buttonTheme]="'primary'"
        [size]="'large'"
        [text]="T.common.save | translate"
        [disabled]="(!valid && !logForm.valid) || !isIncidentCategoriesDropdownValid"
        (clicked)="onAddIncidentLog()"
      ></app-button-rectangle>
    </app-modal-footer>
  </ng-container>
</app-modal>

import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountSettingViewModel } from 'src/app/modules/shared/viewModels/accountSettingViewModel';
import { HttpClient } from '@angular/common/http';
import { AccountSettingTypes } from '../../shared/enums/accountSettingTypes';
import { SeverityViewModel } from '../../shared/models/severityViewModel';
import { LocationViewModel } from '../../shared/viewModels/locationViewModel';
import { TimeZoneViewModel } from '../../settings/viewModels/timeZoneViewModel';
import { AccountDetailsViewModel } from '../../settings/viewModels/accountDetailsViewModel';
import { CountdownSettingsViewModel } from '../../settings/viewModels/countdownSettingsViewModel';
import { DashboardInfoWidgetViewModel } from '../../settings/viewModels/dashboardInfoWidgetViewModel';
import { DashboardWeatherLocationViewModel } from '../../settings/viewModels/dashboardWeatherLocationViewModel';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ImportValidationViewModel } from '../viewModels/ImportValidationViewModel';
import { DownloadedFileViewModel } from '../models/downloadedFileViewModel';
import { EnumBasedLocalisationViewModel } from '../viewModels/enumBasedLocalisationViewModel';
import { FilterTypes } from '../enums/filterTypes';
import { AccountListItemViewModel } from '../../settings/viewModels/accountListItemViewModel';
import { AccountStatisticsViewModel } from '../../settings/viewModels/accountStatisticsViewModel';
import { AccountAdministrationDetailsViewModel } from '../../settings/viewModels/accountAdministrationDetailsViewModel';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';
import { AccountLocalisationViewModel } from '../viewModels/accountLocalisationViewModel';
import { Account } from '../models/account';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AccountSelectItemViewModel } from '../viewModels/accountSelectItemViewModel';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private authenticaitonService: AuthenticationService
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`AccountSettings/`);
  }

  private settingTypes: { settingType: AccountSettingTypes; setting: AccountSettingViewModel }[] = [];

  getChildAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(this.actionUrl + 'ChildAccounts');
  }

  gethubAccountsAsFilters(): Observable<FilterViewModel[]> {
    return this.http.get<FilterViewModel[]>(this.actionUrl + 'HubAccounts/All');
  }

  getTimeZones(): Observable<TimeZoneViewModel[]> {
    return this.http.get<TimeZoneViewModel[]>(this.actionUrl + 'GetTimeZones');
  }

  // ** ACCOUNT SETTINGS **
  getAccountDetails(): Observable<AccountDetailsViewModel> {
    return this.http.get<AccountDetailsViewModel>(this.actionUrl + 'GetAccountDetails');
  }

  updateAccountDetails(accountDetailsViewModel: AccountDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateAccountDetails', accountDetailsViewModel);
  }

  public getAccountSettingByType(settingType: AccountSettingTypes) {
    const matching = this.settingTypes.find((m) => settingType === m.settingType);
    return matching ? of(matching.setting) : this.getAccountSettingByTypeHttp(settingType);
  }

  getAccountSettingByTypeHttp(settingType: AccountSettingTypes): Observable<AccountSettingViewModel> {
    return this.http.get<AccountSettingViewModel>(this.actionUrl + 'GetAccountSettingByType/' + settingType.toString())
    .pipe(map((result) => {
      this.settingTypes = this.settingTypes.filter((m) => settingType !== m.settingType);
      this.settingTypes.push({ settingType: settingType, setting: result });
      return result;
    }));
  }

  updateAccountSetting(setting: AccountSettingViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateAccountSetting', setting).pipe(
      tap(() => {
        this.settingTypes = this.settingTypes.filter((m) => setting.accountSettingType !== m.settingType);
        this.settingTypes.push({ settingType: setting.accountSettingType, setting: setting });
      })
    );
  }

  getCountdownTimerSettings(): Observable<CountdownSettingsViewModel> {
    return this.http.get<CountdownSettingsViewModel>(this.actionUrl + 'GetCountdownTimerSettings/');
  }

  updateCountdownTimerSettings(updateModel: CountdownSettingsViewModel): Observable<void> {
    return this.http.post(this.actionUrl + 'UpdateCountdownTimerSettings/', updateModel).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  hubUploadPDFHelpFile(file: File): Observable<ImportValidationViewModel> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ImportValidationViewModel>(this.actionUrl + 'upload/pdfHelpFile/share', formData);
  }


  uploadPDFHelpFile(file: File): Observable<ImportValidationViewModel> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<ImportValidationViewModel>(this.actionUrl + 'UploadPDFHelpFile', formData);
  }

  downloadPDFHelpFile(url: string): Observable<DownloadedFileViewModel> {
    const options = this.authenticaitonService.getHttpOptions();
    return this.http.get<DownloadedFileViewModel>(url, options).pipe(catchError(() => of(null)));
  }

  getPDFHelpUrl() {
    return `${this.actionUrl}DownloadHelpPDF`;
  }

  //  Incident Dashboard - Info widget

  getDashboardInfoWidgetSettings(settingType: AccountSettingTypes): Observable<DashboardInfoWidgetViewModel> {
    return this.http.get<DashboardInfoWidgetViewModel>(`${this.actionUrl}GetDashboardInfoWidgetSettings/${settingType}`);
  }

  updateDashboardInfoWidgetSettings(
    accoundId: number,
    settingType: AccountSettingTypes,
    updateModel: DashboardInfoWidgetViewModel
  ): Observable<void> {
    return this.http.post(`${this.actionUrl}UpdateDashboardInfoWidgetSettings/${accoundId}/${settingType}`, updateModel).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  // Incident Severity Descriptions
  getIncidentSeveritySettings(): Observable<SeverityViewModel[]> {
    return this.http.get<SeverityViewModel[]>(this.actionUrl + 'GetIncidentSeveritySettings');
  }

  updateIncidentSeveritySettings(updateModel: SeverityViewModel[]): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateIncidentSeveritySettings/', updateModel);
  }

  // Weather location settings
  getIncidentDashboardWeatherSettings(): Observable<DashboardWeatherLocationViewModel[]> {
    return this.http.get<DashboardWeatherLocationViewModel[]>(this.actionUrl + 'GetIncidentDashboardWeatherSettings');
  }

  updateIncidentDashboardWeatherSettings(updateModel: DashboardWeatherLocationViewModel[]): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateIncidentDashboardWeatherSettings/', updateModel);
  }

  // Default Location Settings
  getDefaultLocation(): Observable<LocationViewModel> {
    return this.http.get<LocationViewModel>(this.actionUrl + 'GetDefaultLocation');
  }

  updateDefaultLocation(updateModel: LocationViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateDefaultLocation', updateModel);
  }

  getUseDefaultLocationSetting(): Observable<boolean> {
    return this.http.get<boolean>(this.actionUrl + 'GetUseDefaultLocationSetting');
  }

  updateUseDefaultLocationSetting(): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateUseDefaultLocationSetting', {});
  }

  // Get Account setting list (Not used at the moment)
  getIncidentAccountSettings(): Observable<AccountSettingViewModel[]> {
    return this.http.get<AccountSettingViewModel[]>(this.actionUrl + 'GetIncidentAccountSettings');
  }

  getEnableIncidentChannelsSetting(): Observable<boolean> {
    return this.http.get<boolean>(this.actionUrl + 'GetEnableIncidentChannelsSetting');
  }

  updateEnableIncidentChannelsSetting(enable: boolean): Observable<boolean> {
    return this.http.post<boolean>(this.actionUrl + 'UpdateEnableIncidentChannelsSetting/' + enable, null);
  }
  // Account Administration
  getAccountList() {
    return this.http.get<AccountListItemViewModel[]>(this.actionUrl + `GetAccountList`);
  }

  getAccountStatistics() {
    return this.http.get<AccountStatisticsViewModel>(this.actionUrl + `GetAccountStatistics/`);
  }

  getExportedList(): Observable<DownloadedFileViewModel> {
    const url = `${this.actionUrl}GetExportedList`;
    return this.http.get<DownloadedFileViewModel>(url).pipe(catchError(() => of(null)));
  }

  getAccountAdministrationDetails(accountId: number): Observable<AccountAdministrationDetailsViewModel> {
    return this.http.get<AccountAdministrationDetailsViewModel>(this.actionUrl + 'GetAccountAdministrationDetails/' + accountId);
  }

  addAccount(account: AccountAdministrationDetailsViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'AddAccount', account);
  }

  updateAccount(account: AccountAdministrationDetailsViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'UpdateAccount', account);
  }

  // Enum based localisation

  getEnumBasedLocalisations(filterType: FilterTypes): Observable<EnumBasedLocalisationViewModel[]> {
    return this.http.get<EnumBasedLocalisationViewModel[]>(`${this.actionUrl}GetEnumBasedLocalisations/${filterType}`);
  }

  updateEnumBasedLocalisation(languageSetting: EnumBasedLocalisationViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEnumBasedLocalisation', languageSetting);
  }

  // New language localisation methods
  getAccountLocalisations(): Observable<AccountLocalisationViewModel[]> {
    return this.http.get<AccountLocalisationViewModel[]>(`${this.actionUrl}GetAccountLocalisations`);
  }

  updateAccountLocalisations(localisations: AccountLocalisationViewModel[]): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateAccountLocalisations', localisations);
  }

  updateAccountLocalisation(localisations: AccountLocalisationViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'UpdateAccountLocalisation', localisations);
  }

  getApplicableAccounts(employeeEmail:string): Observable<AccountSelectItemViewModel[]> {
    return this.http.get<AccountSelectItemViewModel[]>(this.actionUrl + `GetApplicableAccounts/${employeeEmail}`);
  }
}

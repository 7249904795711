<app-loading *ngIf="isLoading"></app-loading>
<app-modal *ngIf="!isLoading">
  <div class="modal-header-container d-flex justify-content-between align-items-center section-padding">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.cancel | translate"
      (clicked)="confirmClose()"
    >
    </app-button-transparent>
    <span class="modal-title">{{ T.common.add_item | translate: { item: localisedProject } }}</span>
    <span class="material-symbols-outlined close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-symbols-outlined">error_outline</i>
      {{ error }}
    </div>
  </div>

  <app-modal-body>
    <div [formGroup]="form">
      <section class="row col-row-space custom-gutters section-padding">
        <div class="container-with-bottom-element col-md-12 col-12">
          <app-text-field
            class="modal-project"
            [displayError]="triggerErrors"
            [autofocus]="true"
            [required]="true"
            [maxLength]="250"
            formControlName="title"
            [disableMargin]="true"
            [svg]="projectSvg"
            [placeholder]="(T.common.item_title | translate: { item: localisedProject }) + '*'"
            [formGroup]="form"
            [useReactiveMessages]="true">
          </app-text-field>
        </div>
        <div class="privacy col-md-6 col-12">
          <app-filter-dropdown-new
            [useSearch]="false"
            [singleSelect]="true"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Project_Privacy_Status"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.privacy.one | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="ref-code col-md-6 col-12">
          <app-text-field
            formControlName="refCode"
            [maxLength]="12"
            [placeholder]="T.defaultLocalizations.ref_code.one | translate"
            [useReactiveMessages]="true"
            [formGroup]="form"
          >
          </app-text-field>
        </div>

        <div class="container-with-bottom-element col-md-6 col-12">
          <div>
            <app-datepicker
              *ngIf="!dateReseter"
              [date]="getDate"
              (dateChanged)="dateChangeHandler($event)"
              [placeholder]="T.common.expected_due_date | translate"
              [showPlaceholderFirst]="true"
              [disableMargin]="true"
            >
            </app-datepicker>
          </div>
          <div class="bottom-element d-flex">
            <app-button-transparent
              [app-tooltip]="{
                title: T.common.expected_due_date | translate,
                message:
                  T.common.the_date_by_which_your_item_should_be_completed
                  | translate: { localisedProject: localisedProject, localisedTask: localisedTask }
              }"
              size="small"
              icon="error_outline"
              [text]="T.common.what_s_this | translate"
            ></app-button-transparent>
          </div>
        </div>

        <div class="event col-md-6 col-12">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Event"
            [filterByModuleObjectType]="false"
            [appliedFilters]="filters"
            [typeahead]="true"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>

        <div class="status col-md-6 col-12">
          <app-filter-dropdown-new
            [useSearch]="false"
            [currentlySelectedSettingValue]="'1'"
            [disableMargin]="true"
            [displayError]="triggerErrors"
            [required]="true"
            [singleSelect]="true"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Project_Status"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="(T.common.status.one | translate)"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>

        <div class="description col-12">
          <app-details-editable-field
            [input]="form.controls.description.value ? form.controls.description.value : ''"
            [header]="T.defaultLocalizations.description.one | translate"
            [type]="editableFieldTypes.RichText"
            [canEdit]="true"
            (onUpdate)="updateDescription($event)"
            (onInputChange)="updateDescription($event)"
            [placeholder]="T.common.add_item | translate: { item: T.defaultLocalizations.description.one | translate }"
          ></app-details-editable-field>
        </div>

        <div class="container-with-bottom-element d-flex flex-column col-12 col-md-12">
          <div>
            <app-filter-dropdown-new
              [filterType]="filterTypes.Owner"
              [filterByModuleObjectType]="false"
              [appliedFilters]="filters"
              [disableMargin]="true"
              [typeahead]="true"
              [label]="T.common.assign_item | translate: { item: T.defaultLocalizations.owner.many | translate }"
              (onFilterUpdate)="handleFilterChange($event)">
            </app-filter-dropdown-new>
          </div>
          <div class="bottom-element d-flex">
            <app-button-transparent
              size="small"
              icon="add"
              [text]="T.common.assign_me | translate"
              (clicked)="setAccountForCurrentOwner()"
            ></app-button-transparent>
          </div>
        </div>

        <div class="container-with-bottom-element d-flex flex-column col-12 col-md-12">
          <div>
            <app-filter-dropdown-new
              #dropDown
              [singleSelect]="true"
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Department"
              [disableMargin]="true"
              [appliedFilters]="filters"
              [typeahead]="true"
              [label]="T.common.assign_lead_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
              (onFilterUpdate)="handleFilterChange($event)">
            </app-filter-dropdown-new>
          </div>
          <div class="bottom-element d-flex" *ngIf="employee?.departmentId > 0">
            <app-button-transparent
              size="small"
              icon="add"
              [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
              (clicked)="setMyDepartment()"
            ></app-button-transparent>
          </div>
        </div>

        <app-add-modal-tag-groups
          class="col-12 col-md-12"
          [layoutClasses]="'col-md-6 col-12'"
          [showTitle]="false"
          [filters]="filters"
          [tagGroupObjectType]="objectTypes.Project"
          (onFiltersUpdated)="handleFilterChange($event)">
        </app-add-modal-tag-groups>
      </section>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-transparent
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="d-flex align-items-center">
        <app-button-rectangle
          class="button d-none d-md-block me-2"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.cancel | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [disabled]="form.invalid"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[selectedButtonOption]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
  </app-modal-footer>
</app-modal>
<ng-template #tooltipTemplate>
  <table class="styled-table">
    <tr *ngFor="let keybind of keyboardShortcuts">
      <td>
        <span class="key-shortcut">{{ keybind.key }}</span> -
        <span class="key-description">{{ keybind.description }}</span>
      </td>
    </tr>
  </table>
</ng-template>

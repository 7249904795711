<ng-template #template>
  <div class="incident-popup-wrapper">
    <ng-container *ngFor="let item of incidentItems">
      <app-bubble
        [large]="largeSeverity"
        [color]="getItemSeverity(item.severity).color"
        [value]="getItemSeverity(item.severity).severity"
      >
      </app-bubble>
      <a class="incident-link" [routerLink]="['/:accountId/control/list/' + item.id | url]">{{ item.title }}</a>
    </ng-container>
  </div>
</ng-template>
<div class="document-card" @items>
  <ng-container *ngIf="document">
    <div class="row m-0">
      <div class="col-4 p-0 document-wrapper" [ngClass]="{ 'pl-0': isSmallScreen }">
        <div class="document-container">
          <ng-container *ngIf="documentType === documentTypes.Image">
            <a (click)="showFullScreen()">
              <img [src]="documentUrl" class="image" />
            </a>
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Video">
            <a (click)="openVideoModal()">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Document">
            <a [href]="documentUrl" target="_blank">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Link">
            <a [href]="documentUrl" target="_blank">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
        </div>
        <div class="to-bottom">
          <a class="document-link" (click)="editDocument(document)" *ngIf="!isReadOnly">
            <i class="material-symbols-outlined">edit</i>
          </a>
          <a class="document-link" (click)="deleteDocument(document)" *ngIf="!isReadOnly">
            <i class="material-symbols-outlined">delete</i>
          </a>
          <ng-container *ngIf="incidentItems && incidentItems.length > 0">
            <span
              class="document-link"
              [app-popup]="{
                templateRef: template,
                title: 'Incidents',
                position: 'top',
                arrowPosition: ['bottom', 'center'],
                useHeader: true,
                useFooter: false,
                closeUponSelection: true
              }"
            >
              <i class="material-symbols-outlined">error</i>
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-8 p-0 pl-2" [ngClass]="{ 'pl-1': isSmallScreen }">
        <div class="mr-1">
          <div class="mb-2">
            <span class="description description-large">
              {{ document.title }}
              <i *ngIf="showLockIcon" class="material-symbols-outlined">lock</i>
            </span>
          </div>
          <div class="mb-2">
            <span class="description description-small">
              {{ document.description ? document.description : noDescriptionTranslated }}
            </span>
          </div>
          <div>
            <!-- <ng-container
                            *ngIf="showDocumentCategories && documentCategoriesAsFS && documentCategoriesAsFS.length > 0">
                            <app-overflowing-rounded-filter-settings-lozenge [filterSettings]="documentCategoriesAsFS"
                                [filterType]="filterTypes.Document_Category">
                            </app-overflowing-rounded-filter-settings-lozenge>
                        </ng-container> -->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="uploadViewModel">
    <div class="row m-0">
      <div class="col-4 p-0 document-wrapper">
        <div class="document-container">
          <ng-container *ngIf="documentType === documentTypes.Image">
            <img [src]="uploadViewModelImage" class="image" />
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Video">
            <a [href]="uploadViewModel.fileName" target="_blank">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Document">
            <a [href]="uploadViewModel.fileName" target="_blank">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
          <ng-container *ngIf="documentType === documentTypes.Link">
            <a [href]="uploadViewModel.link" target="_blank">
              <i class="material-symbols-outlined">
                {{ getDocumentIcon() }}
              </i>
            </a>
          </ng-container>
        </div>
        <div class="to-bottom">
          <a class="document-link" (click)="editUpload()" *ngIf="!isReadOnly">
            <i class="material-symbols-outlined">edit</i>
          </a>
          <a class="document-link" (click)="deleteUpload()" *ngIf="!isReadOnly">
            <i class="material-symbols-outlined">delete</i>
          </a>
        </div>
      </div>
      <div class="col-8 p-0 pl-2" [ngClass]="{ 'pl-1': isSmallScreen }">
        <div class="mr-1">
          <div class="mb-2">
            <span class="description description-large">
              {{ uploadViewModel.title }}
              <i *ngIf="showLockIcon" class="material-symbols-outlined">lock</i>
            </span>
          </div>
          <div class="mb-2">
            <span class="description description-small">
              {{ uploadViewModel.description ? uploadViewModel.description : noDescriptionTranslated }}
            </span>
          </div>
          <!-- <ng-container
                        *ngIf="showDocumentCategories && documentCategoriesAsFS && documentCategoriesAsFS.length > 0">
                        <app-overflowing-rounded-filter-settings-lozenge [filterSettings]="documentCategoriesAsFS"
                            [filterType]="filterTypes.Document_Category">
                        </app-overflowing-rounded-filter-settings-lozenge>
                    </ng-container> -->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="showFullScreenImage">
  <app-image-preview
    [url]="documentUrl"
    [title]="document.title"
    [description]="document.description"
    (onStateChange)="hideFullScreen()"
  ></app-image-preview>
</ng-container>

import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardLayoutWidgetViewModel } from 'src/app/modules/shared/viewModels/dashboard/dashboardLayoutWidgetViewModel';

@Component({
  selector: 'app-dashboard-widget-content-v2',
  templateUrl: './dashboard-widget-content.component.html',
  styleUrls: ['./dashboard-widget-content.component.scss'],
})
export class DashboardWidgetContentComponentV2 implements AfterViewInit, OnChanges {
  @ViewChild('widgetContainer', { read: ViewContainerRef }) widgetContainer: ViewContainerRef;

  @Input() widget: DashboardLayoutWidgetViewModel;
  @Input() displayInModal: boolean = false;
  @Input() isSummaryWidget: boolean = false;
  @Input() inputParams: unknown;

  @Output() closeModal = new EventEmitter();

  private componentRef: ComponentRef<any>;
  private isInitExecuded = false;
  private readonly subscriptions = new Subscription();

  constructor(
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.componentRef = this.widgetContainer.createComponent(this.widget.component);
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.displayInModal = this.displayInModal;

    this.subscriptions.add(
      this.componentRef.instance.closeAfterItemIsSelected?.subscribe(() => this.closeModal.emit())
    )

    if(this.inputParams && typeof this.inputParams === 'object') {
      for(const key in this.inputParams) {
        this.componentRef.instance[key] = this.inputParams[key] as unknown;
      }
    }

    this.isInitExecuded = true;
    this.changeDetector.detectChanges();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSummaryWidget && changes.widget && this.isInitExecuded ) {
      this.widgetContainer.clear();
      this.componentRef = this.widgetContainer.createComponent(this.widget.component);
      this.componentRef.instance.widget = this.widget;
      this.componentRef.instance.displayInModal = this.displayInModal;

      if(this.inputParams && typeof this.inputParams === 'object') {
        for(const key in this.inputParams) {
          this.componentRef.instance[key] = this.inputParams[key] as unknown;
        }
      }

      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

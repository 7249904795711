import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './modules/shared/components/common/not-found/not-found.component';
import { AuthGuardService } from './modules/auth/auth-guard.service';
import { SigninOidcService } from './modules/auth/signin-oidc.service';
import { SignoutOidcService } from './modules/auth/signout-oidc.service';
import { unAuthorisedComponent } from './unauthorised/unauthorised.component';
import { ModuleTypes } from './modules/settings/enums/moduleTypes';
import { HistoryFeedNewComponent } from './modules/shared/components/common/history-feed-new/history-feed-new.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { HistoryFeedTheme } from './modules/shared/enums/historyFeedTheme';
import { environment } from 'src/environments/environment';
import { SignOidcComponent } from './modules/auth/signOidc-component/sign-oidc.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { UserAccountSelectComponent } from './modules/account-selection/components/user-accounts/user-accounts.component';
import { LeaveGuardService } from './modules/shared/guards/leave/leave.guard';
import { NoAccessComponent } from './no-access/no-access.component';

const routes: Routes = [
  {
    path: ':accountId/userAccount',
    component: UserAccountSelectComponent,
    canActivate: [AuthGuardService],
    data: { moduleType: ModuleTypes.AccountSelect },
  },
  //{ path: ':accountId/userAccount', loadChildren: () => import('./modules/account-selection/account-selection.module').then(m => m.accountSelectionModule), data: { moduleType: ModuleTypes.AccountSelect } },

  { path: ':accountId/unauthorized', component: unAuthorisedComponent, data: { title: 'Unauthorized' } },
  { path: ':accountId/no-access', component: NoAccessComponent, data: { title: 'No Access' } },
  {
    path: ':accountId/history',
    component: HistoryFeedNewComponent,
    canActivate: [AuthGuardService],
    data: { animation: 'activity', title: 'History Feed', bgTheme: HistoryFeedTheme.GREY, moduleType: ModuleTypes.Incidents },
  },
  { path: '', redirectTo: '/0/mytrack/planning', pathMatch: 'full' },
  { path: 'signin-oidc', component: SignOidcComponent, canActivate: [SigninOidcService] },
  { path: 'signout-oidc', component: SignOidcComponent, canActivate: [SignoutOidcService] },
  { path: ':accountId/search', loadChildren: () => import('./modules/searching/searching.module').then((m) => m.SearchingModule) },
  {
    path: ':accountId/control',
    loadChildren: () => import('./modules/incidents/incidents.module').then((m) => m.IncidentsModule),
    data: { moduleType: ModuleTypes.Incidents },
  },
  {
    path: ':accountId/gantt',
    loadChildren: () => import('./modules/gantt/gantt.module').then((m) => m.GanttModule),
    data: { moduleType: ModuleTypes.Planning },
  },
  {
    path: ':accountId/gantt/:id',
    loadChildren: () => import('./modules/gantt/gantt.module').then((m) => m.GanttModule),
    data: { moduleType: ModuleTypes.Planning },
  },
  {
    path: ':accountId/reports',
    loadChildren: () => import('./modules/reporting/reporting.module').then((m) => m.ReportingModule),
    data: { moduleType: ModuleTypes.Reporting },
    canDeactivate: [LeaveGuardService],
  },
  {
    path: ':accountId/hub',
    loadChildren: () => import('./modules/accountHub/account-hub.module').then((m) => m.AccountHubModule),
    data: { moduleType: ModuleTypes.AccountHub },
  },
  {
    path: ':accountId/runsheets',
    loadChildren: () => import('./modules/runsheets/runsheet.module').then((m) => m.RunSheetModule),
    data: { moduleType: ModuleTypes.Runsheets },
  },
  {
    path: ':accountId/settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    data: { moduleType: ModuleTypes.Settings },
  },
  {
    path: ':accountId/risk',
    loadChildren: () => import('./modules/risk/risk.module').then((m) => m.RiskModule),
    data: { moduleType: ModuleTypes.Risk },
  },
  {
    path: ':accountId/planning',
    loadChildren: () => import('./modules/planning/planning.module').then((m) => m.PlanningModule),
    data: { moduleType: ModuleTypes.Planning },
  },
  {
    path: ':accountId/sustainability',
    loadChildren: () => import('./modules/sustainability/sustainability.module').then((m) => m.SustainabilityModule),
    data: { moduleType: ModuleTypes.Sustainability },
  },
  {
    path: ':accountId/mytrack',
    loadChildren: () => import('./modules/my-track/my-track.module').then((m) => m.MyTrackModule),
    data: { moduleType: ModuleTypes.My_Track },
  },
  {
    path: ':accountId/events',
    loadChildren: () => import('./modules/events/events.module').then((m) => m.EventModule),
    data: { moduleType: ModuleTypes.Events },
  },
  { path: ':accountId/access-denied', component: AccessDeniedComponent, data: { title: 'Access Denied' } },
  { path: 'home', redirectTo: '/:accountId/mytrack/planning' },
  { path: 'projects', redirectTo: '/:accountId/planning/list' },
  { path: 'tasks', redirectTo: '/:accountId/planning/list' },
  { path: 'calendar', redirectTo: '/:accountId/planning/calendar' },
  { path: 'history', redirectTo: '/:accountId/planning/history' },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [AuthGuardService],
    data: {
      title: '404 - Not Found',
      meta: [{ name: 'description', content: '404 - Error' }],
      links: [
        { rel: 'canonical', href: 'http://blogs.example.com/bootstrap/something' },
        { rel: 'alternate', hreflang: 'es', href: 'http://es.example.com/bootstrap-demo' },
      ],
    },
  },
];

if (environment.enableDevModule === 'true') {
  routes.push({
    path: ':accountId/devtools',
    loadChildren: () => import('./modules/devtools/devtools.module').then((m) => m.DevtoolsModule),
  });
}

export const AppRoutes = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    preloadingStrategy: QuicklinkStrategy
});

import { IEnvironment } from 'src/app/modules/shared/models/interfaces/environment.interface';

export const environment: IEnvironment = {
  CONFIGURATION: 'production',
  API_URL: 'https://apiv3.wetrack.com',
  DEFAULT_AVATAR: 'https://api.wetrack.com/Resource/assets/images/icon-user-grey.png',
  DEFAULT_AVATAR_INVERSE: 'https://api.wetrack.com/Resource/assets/images/icon-user-white.png',
  CDN_URL: 'https://api.wetrack.com/resource/',
  GANTT_EXPORT_URL: 'https://ganttexporter.wetrack.com/gantt',
  DHX_GRID_EXPORT_URL: 'https://gridexporter.wetrack.com/excel',
  API_VERSION: 'v1',
  Issuer: 'https://auth.wetrack.com',
  ClientId: 'com.wetrack.frontend.spa.client.live',
  enableDevModule: 'false',
  enableExtarnalAPIs: 'true',
  MOBILE_APP_ID: 'com.wetrack.www',
  disableZoneModal: false,
  DOMAIN: 'wetrack-stage.wetrack.com',
  isOnPremise: false,
  mapProvider: 'com.google.maps',
  CLUSTER_NAME: 'WT-EMEA',
  google_maps: {
    key: 'AIzaSyD8A4sYQcB05T7963B0Daa69ijv3DytHgQ',
    version: 'weekly',
    map_id: '13e1ccac041b5186'
  },
  PENDO_API_KEY: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiI3Y2EyMWE1NGQ0ZmRjNzk5NDRkZmRkOGY2MTFhNGVjYTFlM2NlY2JmOGJmYTE0OTQ2ZWEwNjA3ZjE3ZGNiMmRlZjljODYyMDNhNGIzYjAzZWEyMDhmZGJkNDBjOTA3YTM4NmMzZDNjMGNkOGNlMjVlMzlkMjgxM2E3OTdlN2ZkZTRmZjA0NThkODZiNDEyYTYzZTY1MWU3OTNiYjgwZjE4YmE0NDJiZDEzOWQ1ZGEzNDkzOTZiZmEwNWE2OGJlN2Q3NWE1N2Q2MDczOTU3MDI5ZDRlMjg5ZGIxMDI1NmRhNDVkNTY5OWY4ZjM4MzVmNmY5Mzc1YTYzZDk5ZmQxOWUzYmVlN2JjZGYzOWVmOWIxZDQwYWViMjliZThkMTRjODcuZTljOGI4OGJlZGFmZmRiMmJiNTU4MWUyZDE1ZThlZjQuMjRmMGUzZjNlNGNmODZhNGRkMjg4NmQzODZiYTMyMGNjNDZhOTY2NWFkN2FhNjRhOTczYWQ0ZDZlMWMyOTU4NSJ9.iZSlp-h-5W5ZRznmNtmL19VMceKX9N8c4K7fe4s6C_C8YseMap4Hnck2sWydmmpU2swpsKwRbalBaNyP1icRml8rfiv_E9LfbaXArG7MOWWC-NdDmjREzd4IFAIPhTMNnb3axZ8hPEvDrDWIMNNorb9M0QVV1VTQRL26EVl2d94'
};

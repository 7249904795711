<app-modal>
  <app-modal-header [title]="headerText" [showDelete]="false" (closed)="onClose()"> </app-modal-header>

  <app-modal-body>
    <div class="info-container">
      <div class="info-icon">
        <i class="material-symbols-outlined">error_outline</i>
      </div>
      <div class="info-text">
        {{ infoForItem }}
      </div>
    </div>

    <div *ngIf="objectType === objectTypes.Task_Group">
      <app-search-dropdown-r [objects]="taskGroupSortOptions" [bindProperty]="'value'"
        [currentlySelectedObject]="selectedTaskGroupOption"
        [label]="(T.common.sort | translate) + ' ' + localisedTaskGroup"
        (objectSelected)="onTaskGroupSortMethodChanged($event)">
      </app-search-dropdown-r>
    </div>
    <div class="sorting-wrapper" *ngIf="objectType !== objectTypes.Task_Group">
      <div class="header-row" [ngClass]="{'justify-between': !showSortingOptions}">
        <div class="text-left" [ngClass]="{'f-45': showSortingOptions}">
          {{ T.common.attribute.one | translate }}
        </div>
        <div [ngClass]="{'f-40': showSortingOptions}">
          {{ T.common.display_on_card | translate }}
        </div>
        <div class="f-15" *ngIf="showSortingOptions">
          {{ T.common.sort | translate }}
        </div>
      </div>
      <ng-container *ngFor="let filter of filters; trackBy: isChecked">
        <div class="table-row" [ngClass]="{'justify-between': !showSortingOptions}" *ngIf="filter.filterType !== 0">
          <div class="text-left text-truncate" [ngClass]="{'f-45': showSortingOptions}">
            {{ getFilterName(filter) }}
          </div>
          <div [ngClass]="{'f-40': showSortingOptions}">
            <app-checkbox [isChecked]="isFilterChecked(filter)" [disabled]="filter.isModalReadOnly"
              (checked)="changeFilterSetting(filter)">
            </app-checkbox>
          </div>
          <div class="d-flex f-15" *ngIf="showSortingOptions">
            <i class="material-symbols-outlined"
              [ngClass]="{ selected: isAppliedSorting(filter, true, true), disabled: !isFilterChecked(filter) }"
              (click)="changeAppliedSorting(filter, true, true)">
              arrow_circle_up
            </i>
            <i class="material-symbols-outlined"
              [ngClass]="{ selected: isAppliedSorting(filter, true, false), disabled: !isFilterChecked(filter) }"
              (click)="changeAppliedSorting(filter, true, false)">
              arrow_circle_down
            </i>
          </div>
        </div>
      </ng-container>
    </div>
    <app-filter-sorting-headers [objectType]="objectType" [incidentItemType]="incidentItemType" (subtitleTypeChanged)="changeSubtitleSetting($event)"></app-filter-sorting-headers>
    <div class="lozenge-checkbox-wrapper">
      <app-checkbox-with-label [label]="T.common.sort_edit.hide_attributes_label | translate"
        [intialState]="showEmptyLozenges" (clicked)="changeEmptyLozengeState()">
      </app-checkbox-with-label>
      <app-checkbox-with-label [label]="T.common.show_lozenges | translate"
        [intialState]="showLozengeInitialState" (clicked)="changeShowLozengesSetting()">
      </app-checkbox-with-label>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="footer">
      <div class="buttons-group">
        <app-button-transparent [text]="T.common.sort_edit.reset_to_default | translate" icon="highlight_off"
          size="medium" (clicked)="resetSettings()">
        </app-button-transparent>
      </div>

      <div class="buttons-group">
        <app-button-transparent [text]="T.common.cancel | translate" size="medium" (clicked)="onClose()">
        </app-button-transparent>
        <app-button-rectangle class="modal-saveClose" (clicked)="onSaveAndExit()" size="medium" button-theme="primary"
          [text]="T.common.apply | translate"></app-button-rectangle>
      </div>
    </div>
  </app-modal-footer>
</app-modal>

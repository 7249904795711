<ng-container *ngIf="!sideAccordion">
  <section class="accordion" [class.mobile]="isMobile">
    <div
      *ngFor="let item of items; index as i"
      class="accordion-item-details"
      [class.disabled]="item.disabled"
      [class.active]="expanded.has(i)"
    >
      <ng-container
        *ngIf="item.visible"
        [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
        [ngTemplateOutletContext]="{ $implicit: item, index: i, toggle: getToggleState(i) }"
      ></ng-container>
      <div
        class="accordion-content"
        [class.expanded]="expanded.has(i)"
        [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
      >
        <ng-container *ngTemplateOutlet="item?.content?.templateRef"></ng-container>
      </div>
    </div>
  </section>

  <ng-template #defaultHeader let-item let-index="index">
    <header
      class="accordion-header"
      [class.emphasize-header]="emphasizedHeaders"
      [class.header-border-top]="!isMobile && index > 0"
      (click)="item.disabled ? {} : toggleState(index)"
    >
      <button class="accordion-toggle-btn" [disabled]="item.disabled">
        <i class="material-symbols-outlined expand-icon">expand_more</i>
      </button>
      <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
    </header>
    <ng-template #defaultTitle>
      <div class="accordion-title" localise>{{ item?.title }}</div>
    </ng-template>
  </ng-template>
</ng-container>

<ng-container *ngIf="sideAccordion">
  <section class="side-accordion accordion">
    <div class="side-options-list">
      <div
        *ngFor="let item of items; index as i"
        class="side-accordion-item-details accordion-item-details"
        [class.disabled]="item.disabled"
        [class.active]="expanded.has(i)"
      >
        <ng-container
          *ngIf="item.visible"
          [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
          [ngTemplateOutletContext]="{ $implicit: item, index: i, toggle: getToggleState(i) }"
        ></ng-container>
      </div>
    </div>
    <div class="side-option-content option-content">
      <ng-container *ngTemplateOutlet="getItemContent()?.content?.templateRef"></ng-container>
    </div>
  </section>
  <ng-template #defaultHeader let-item let-index="index">
    <header class="side-accordion-header accordion-header" (click)="item.disabled ? {} : toggleState(index)">
      <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
      <button class="side-accordion-toggle-btn" [disabled]="item.disabled">
        <i class="material-symbols-outlined side-expand-icon">expand_more</i>
      </button>
    </header>
    <ng-template #defaultTitle>
      <div class="side-accordion-title accordion-title" localise>{{ item?.title }}</div>
    </ng-template>
  </ng-template>
</ng-container>

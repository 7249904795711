import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class AdminAccountGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();

      return new Observable<boolean>((observer: Observer<boolean>) => {
        if (account && account.id === 85) {
          observer.next(true);
        } else {
          void this.router.navigateByUrl(`${account.accountUID}/settings`);
        }
      });
    }
  }
}

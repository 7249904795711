<app-modal>
  <app-modal-header [title]="headerTitle" (closed)="close()"> </app-modal-header>
  <app-modal-body>
    <app-dashboard-widget-content-v2
      [widget]="widget"
      [displayInModal]="true"
      [inputParams]="inputParams"
      (closeModal)="close()">
    </app-dashboard-widget-content-v2>
  </app-modal-body>
</app-modal>

import {
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription } from 'rxjs';
import { Employee } from '../../../models/employee';
import { ObjectTypes } from '../../../enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-add-modal-tag-groups',
  templateUrl: './add-modal-tag-groups.component.html',
  styleUrls: ['./add-modal-tag-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModalTagGroupsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  employee: Employee;

  public filterTypes = FilterTypes;
  public allTagGroupFilters: FilterViewModel[] = [];
  public allTagFilters: FilterViewModel[] = [];
  public classes = '';
  public readonly T = T;
  private cache: { [key: string]: FilterViewModel[] } = {};
  @Input() showTitle = true;
  @Input() filters: FilterViewModel[];
  @Input() tagGroupObjectType: ObjectTypes;
  @Input() layoutClasses: string;
  @Input() showOutlined: boolean = false;
  @Input() removeBorders: boolean = false;
  @Input() useSearch: boolean = true;
  @Input() removeIcon: boolean = false;
  @Input() disableMargin: boolean = false;
  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.classes = this.layoutClasses;
    this.employee = this.authService.getCurrentEmployee();
    this.initFilters();
  }

  initFilters() {
    this.allTagGroupFilters = this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Tag_Group)
      .filter((s) => s.displayForGlobalObjectType === this.tagGroupObjectType);

    this.allTagFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Tag);

    this.allTagGroupFilters = this.allTagGroupFilters.filter((group) => {
      const matchingTags = this.allTagFilters.filter(
        (f) => f.relatedObjectId && f.relatedObjectId.toString() === group.filterValue.toString()
      );
      return matchingTags && matchingTags.length;
    });

    this.changeDetectorRef.detectChanges();
  }

  updateFilters(filters: FilterViewModel[], tagGroup: string) {
    let updatedFilters: FilterViewModel[] = this.filters;

    filters.forEach((f) => {
      const match = updatedFilters.find((uf) => uf.filterValue === f.filterValue);
      if (match === undefined) {
        updatedFilters.push(f);
      }
    });

    const filtersByTagGroup = this.getTagFiltersByTagGroup(tagGroup);
    filtersByTagGroup.forEach((ftg) => {
      const match = filters.find((f) => f.filterValue === ftg.filterValue);
      if (match === undefined) {
        updatedFilters = updatedFilters.filter((f) => f.filterValue != ftg.filterValue);
      }
    });

    this.onFiltersUpdated.next(updatedFilters);
    this.cache[tagGroup] = updatedFilters.filter((f) => f.relatedObjectId === +tagGroup);
    this.changeDetectorRef.detectChanges();
  }

  getTagFiltersByTagGroup(tagGroup: string): FilterViewModel[] {
    if (this.cache[tagGroup]) {
      return this.cache[tagGroup];
    }

    const allTagFiltersByTagGroup = this.allTagFilters
      .filter((f) => +f.relatedObjectId === +tagGroup)
      .map((t) => t.filterValue);

    const filters = this.filters
      .filter((f) => allTagFiltersByTagGroup
      .some((tagValue) => +f.filterValue === tagValue));

    if (filters && filters.length) {
      filters.forEach((f) => (f.relatedObjectId = +tagGroup));
    }

    this.cache[tagGroup] = filters;
    return filters;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}

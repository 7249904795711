import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { T } from 'src/assets/i18n/translation-keys';
import { EditableFieldTypes } from '../../../enums/editableFieldTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { MilestoneTypes } from '../../../enums/milestoneTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { TaskSubTypes } from '../../../enums/taskSubTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalisationService } from '../../../services/localisation.service';
import { IconTypes } from '../../../types/iconTypes';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { RagHelper } from '../../../utilities/rag.utilities';
import { ModalHeaderIconType } from '../../common/modal-header/modal-header.component';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { AccountHubService } from 'src/app/modules/accountHub/services/account-hub.service';
import { Subscription } from 'rxjs';
import { HubModalDataInterface } from '../interfaces/hub-modal-data.interface';
import { RouteHelper } from '../../../utilities/route.helper';
import { TaskService } from 'src/app/modules/planning/services/task-service';
import { TaskDetailsViewModel } from 'src/app/modules/planning/viewModels/projects/taskDetailsViewModel';
import { TaskRagUpdate } from '../../../types/taskRagUpdateType';
import { RAGUpdate } from 'src/app/modules/planning/models/ragUpdate';
import { AlertService } from '../../../services/alert.service';
import { ObjectEventEmitters } from '../../../events/object.events';
import { Account } from '../../../models/account';
import * as moment from 'moment';

@Component({
  selector: 'app-task-details-modal',
  templateUrl: './task-details-modal.component.html',
  styleUrls: ['./task-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDetailsModalComponent implements OnInit, HubModalDataInterface {
  @Input() object: any;
  @Input() account: Account;
  @Input() objectType: ObjectTypes;
  @Input() allowedFilters: FilterViewModel[] = [];
  @Input() readonly = false;

  @Output() objectUpdated: EventEmitter<any> = new EventEmitter();

  protected mobileWidth: number;
  protected doubleWidthPixels: 800;
  protected wrapper: ElementRef<HTMLElement>;
  protected subscriptions = new Subscription();

  public readonly modalHeaderIconType = ModalHeaderIconType;
  public readonly filterTypes = FilterTypes;
  public readonly objectTypes = ObjectTypes;
  public readonly editableFieldTypes = EditableFieldTypes;
  public readonly T = T;

  public loading = false;
  public canEdit = true;
  public descriptionExpanded = false;
  public historyExpanded = false;
  public additionalDetailsExpanded = false;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly ts: TranslateService,
    private readonly localisationService: LocalisationService,
    private readonly authenticationService: AuthenticationService,
    private readonly accountHubService: AccountHubService,
    private readonly taskService: TaskService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly objectEventEmitters: ObjectEventEmitters
  ) {}

  ngOnInit(): void {
    if (!this.account) {
      this.account = this.authenticationService.getCurrentAccount();
    }

    if (this.readonly) {
      this.canEdit = false;
    }

    if (typeof this.object === 'number') {
      this.getTaskDetails(this.object);
    }
    else{
      this.allowedFilters = this.allowedFilters.filter(f=>f.accountId == this.object.accountId);
    }


  }

  public close() {
    this.bsModalRef.hide();
  }

  public get isMilestone(): boolean {
    return (
      +this.object.filters.find((f) => f.filterType === FilterTypes.Milestone_Type).filterValue !== MilestoneTypes.No_Milestone
    );
  }

  public get milestoneColor(): string {
    const type = +this.object.filters.find((f) => f.filterType === FilterTypes.Milestone_Type).filterValue;
    const color = type === MilestoneTypes.Gold ? 'gold' : type === MilestoneTypes.Silver ? 'silver' : 'bronze';

    return color;
  }

  public get localizedMilestone() {
    const type = +this.object.filters.find((f) => f.filterType === FilterTypes.Milestone_Type).filterValue;
    return this.localisationService.localiseFilterValueByFilterType(type, FilterTypes.Milestone_Type);
  }

  public get taskExpectedDue(): string {
    return ''; //this.task.filters.find(f => f.filterType === FilterTypes.)
  }

  public get ragColor(): string {
    return RagHelper.getRAGColourHexFromValue(this.object.rag);
  }

  get ragDescription() {
    return RagHelper.getRAGDescriptionFromValue(
      this.object.rag,
      this.authenticationService.getCurrentAccount().id,
      this.ts
    );
  }

  public get taskIcon(): string {
    return IconUtilities.getSvgForIconType(+this.iconType);
  }

  public get iconType(): string {
    let iconType = IconTypes.Task;

    const taskType = this.taskType;
    switch (taskType) {
      case TaskSubTypes.Task:
        iconType = IconTypes.Task;
        break;
      case TaskSubTypes.Sub_Task:
        iconType = IconTypes.Sub_Task;
        break;
      default:
        break;
    }

    const iconTypeStr = EnumUtilities.items(IconTypes).find((t) => t.key === iconType).value;
    return iconTypeStr;
  }

  public get taskType(): TaskSubTypes {
    return +this.object.filters.find((f) => f.filterType === FilterTypes.Task_Type)?.filterValue;
  }

  public get headerText() {
    return `${this.getLocalizedTask()} Quick View`;
    //return this.translateService.instant(T.common.item_details, { item: this.getLocalizedTask() });
  }

  public get dueDateIndicator(): { label: string; color: string } {
    if (!this.object.due) {
      return null;
    }
    const dueDate = moment(this.object.due);
    const today = moment();

    if (dueDate.isBefore(today)) {
      return {
        label: this.ts.instant(T.common.overdue.one),
        color: 'var(--wt-red)'
      };
    }  else if (dueDate.isSame(today, 'day')) {
      return {
        label: this.ts.instant(T.common.due_today),
        color: 'var(--wt-black)'
      };
    } else if (dueDate.isSame(today.add(1, 'day'), 'day')) {
      return {
        label: this.ts.instant(T.calendar.due_tomorrow),
        color: 'var(--wt-black)'
      };
    } else if (dueDate.isAfter(today.add(1, 'day'), 'day')) {
      return {
        label: this.ts.instant(T.calendar.due_in_count_days, { count: dueDate.diff(today, 'days') }),
        color: 'var(--wt-grey-dark)',
      };
    }
    return null;
  }

  public get ownersCount(): number {
    return this.object.filters.filter(f => f.filterType === FilterTypes.Owner).length;
  }

  public get taskGroup(): string {
    return this.allowedFilters
      .filter(af => af.filterType === FilterTypes.Task_Group)
      .find(af => +af.filterValue === +this.object.filters.find(f => f.filterType === FilterTypes.Task_Group)?.filterValue)?.filterText;
  }

  public navigateToDetaislPage() {
    const route = `${this.account.url}${RouteHelper.getRoute(this.account.accountUID,this.objectType)}${this.object.id}`;
    window.open(route, '_blank');
  }

  public onTitleUpdate(newTitle: string): void {
    this.object.title = newTitle;

    let titleFilte = this.object.filters.find(f => f.filterType === FilterTypes.Title);
    if(!titleFilte){

      titleFilte = new FilterViewModel();
      titleFilte.filterType = FilterTypes.Title;
      titleFilte.filterValue = newTitle;
      titleFilte.filterText = newTitle;
      titleFilte.id = `${titleFilte.filterType}:${titleFilte.filterValue}`;
      titleFilte.filterAction = FilterActionTypes.Add;

      this.object.filters.push(titleFilte);
    }
    else{
      titleFilte.filterValue = newTitle;
      titleFilte.filterText = newTitle;
      titleFilte.filterAction = FilterActionTypes.Update;
    }

    this.subscriptions.add(
      this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType,[titleFilte])
      .subscribe((res) => {
        this.objectUpdated.emit(this.object);
      }));

  }

  public onRagUpdate(ev: TaskRagUpdate) {
    const ragFilter = ev.filters.find((f) => f.filterType === FilterTypes.RAG);
    if (ragFilter) {
      this.object.rag = ragFilter.filterValue;
    }

    this.object.filters = ev.filters;

    const ragUpdate = new RAGUpdate();
    ragUpdate.taskId = this.object.id;
    ragUpdate.comment = ev.comment;
    ragUpdate.ragStatus = this.object.rag;
    ragUpdate.projectId = this.object.filters.find(a => a.filterType === FilterTypes.Project).filterValue;

    this.subscriptions.add(
      this.taskService.updateHubRAG(ragUpdate).subscribe((r) => {
        this.objectUpdated.emit(this.object);
      })
    );
  }

  public onDescriptionUpdate(description: string): void {
    this.object.description = description;

    let filter = this.object.filters.find(f => f.filterType === FilterTypes.Description);
    if(!filter){

      filter = new FilterViewModel();
      filter.filterType = FilterTypes.Description;
      filter.filterValue = description;
      filter.filterText = description;
      filter.id = `${filter.filterType}:${filter.filterValue}`;
      filter.filterAction = FilterActionTypes.Add;

      this.object.filters.push(filter);
    }
    else{
      filter.filterValue = description;
      filter.filterText = description;
      filter.filterAction = FilterActionTypes.Update;
    }

    this.subscriptions.add(
      this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType,[filter])
      .subscribe((res) => {
        this.objectUpdated.emit(this.object);
      }));



  }

  public expandCollapseDescBtnClicked() {
    this.descriptionExpanded = !this.descriptionExpanded;
  }

  public updateFilters(filters: FilterViewModel[], filterType: FilterTypes) {

    const updatedFilter = filters.filter((f) => f.filterType === filterType && f.filterAction !== FilterActionTypes.None);
    const objectFilters =this.object.filters.filter(r=>r.filterType == filterType);
    const removedFilters = objectFilters.filter(r=>!filters.find(f=>f.id == r.id));

    if(removedFilters && removedFilters.length > 0){
      removedFilters.forEach(r=>r.filterAction = FilterActionTypes.Remove);
      updatedFilter.push(...removedFilters);
    }

    this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType, updatedFilter)
      .subscribe((res) => {
        this.object.filters = filters;
        this.objectUpdated.emit(this.object);
      });
  }

  public onHistoryEntriesUpdate(count: number) {

    this.changeDetectorRef.detectChanges();

  }

  public onDatesSaved(dates: any) {
    const startDate = dates.startDate;
    const endDate = dates.endDate;
    const dateFilters = this.object.filters.filter((f) => f.filterType === FilterTypes.Date);

    const startDateFilter = dateFilters.find((f) => f.dateProperty == FilterDateOptions.Start_Date);
    startDateFilter.filterValue = startDate;
    startDateFilter.filterText = startDate;
    startDateFilter.id = `${startDateFilter.filterType}:${startDateFilter.dateProperty}:${startDateFilter.filterValue}`;
    startDateFilter.filterAction = FilterActionTypes.Add;

    const dueDateFilter = dateFilters.find((f) => f.dateProperty == FilterDateOptions.Due_Date);
    dueDateFilter.filterValue = endDate;
    dueDateFilter.filterText = endDate;
    dueDateFilter.id = `${dueDateFilter.filterType}:${dueDateFilter.dateProperty}:${endDate}`;
    dueDateFilter.filterAction = FilterActionTypes.Add;

    this.object.start = startDate;
    this.object.due = endDate;

    this.accountHubService
      .updateObject(this.object.id, this.account.id, this.objectType, [startDateFilter, dueDateFilter])
      .subscribe((e) => {
        startDateFilter.filterAction = FilterActionTypes.None;
        this.objectUpdated.emit(this.object);
      });
  }

  public onItemIndexClicked(index: number) {
    if(index === 0) {
      this.additionalDetailsExpanded = !this.additionalDetailsExpanded;
    } else if (index === 1) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  private getLocalizedTask(): string {
    let localizeWord = '';

    const localizedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    const localizedSubTask = this.localisationService.localiseSubTask();

    if (this.object) {
      const taskType = this.taskType;
      switch (taskType) {
        case TaskSubTypes.Task:
          localizeWord = localizedTask;
          break;
        case TaskSubTypes.Sub_Task:
          localizeWord = localizedSubTask;
          break;
        default:
          break;
      }
    }

    return localizeWord;
  }

  private getTaskDetails(id: number) {
    this.loading = true;
    this.subscriptions.add(
      this.taskService.details(id).subscribe((res: TaskDetailsViewModel) => {
        this.object = res;
        this.allowedFilters = this.allowedFilters.filter(f=>f.accountId == res.accountId);

        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }
}

import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable()
export class Configuration {
  public CDNServer = environment.CDN_URL;
  public GanttExportURL = environment.GANTT_EXPORT_URL;
  public ApiVersion = environment.API_VERSION;
  public BackEndAPI = environment.API_URL;
  public configuration = environment.CONFIGURATION;
  private maxFileSize = 0;
  private allowedUploadTypes = '';
  private resourceFolderName = 'Resource';
  private readonly map_provider = environment.mapProvider;
  private readonly google_maps_config = environment.google_maps;
  private readonly pendoApiKey = environment.PENDO_API_KEY;

  public get MaxFileSize() {
    return this.maxFileSize;
  }

  public get AllowedUploadTypes() {
    return this.allowedUploadTypes;
  }

  public SetMaxFileSize(maxFileSize: number) {
    this.maxFileSize = maxFileSize;
  }

  public SetAllowedUploadTypes(allowedTypes: string) {
    this.allowedUploadTypes = allowedTypes;
  }

  public SetResourceFolderName(resourceFolderName: string) {
    this.resourceFolderName = resourceFolderName;
  }

  public get ResourceFolderName() {
    return this.resourceFolderName;
  }

  public get mapProvider() {
    return this.map_provider;
  }

  public get googleMapsConfiguration() {
    return this.google_maps_config;
  }

  public get pendoAPIKey() {
    return this.pendoApiKey;
  }

  public Href: string;

  public mode: string = 'Prod';

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    const href: string = isPlatformBrowser(this.platformId) ? window.location.href : 'n/a';

    this.Href = href;
  }

  public buildEndpoint(urlPath: string): string {
    return `${this.BackEndAPI}/${this.ApiVersion}/${urlPath}`;
  }

  public buildDocumentUrl(urlPath: string): string {
    return `${this.BackEndAPI}/${urlPath}`;
  }

  public buildStaticFileUrl(urlPath: string) {
    if (urlPath.startsWith('http')) {
      return urlPath;
    }

    return !urlPath.includes(this.resourceFolderName)
      ? `${this.BackEndAPI}/${this.resourceFolderName}/${urlPath}`
      : this.buildDocumentUrl(urlPath);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../models/employee';
import { AuthenticationService } from '../services/authentication.service';
import { Account } from '../models/account';

@Pipe({
  name: 'url',
})
export class UrlPipe implements PipeTransform {
  private employee: Employee;
  private account: Account;
  constructor(private readonly authenticationService: AuthenticationService) {
    this.employee = authenticationService.getCurrentEmployee();
    this.account = authenticationService.getCurrentAccount();
  }

  transform(url: string): string {
    if (url.includes(':accountId')) {
      url = url.replace(':accountId', this.account.accountUID);
    }
    return url;
  }
}
